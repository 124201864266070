import { css } from '@emotion/core';
export const section__mobile_view = css`
  .ant-tabs-nav {
    width: 90%;
    margin: auto;
  }

  .ant-tabs-tab {
    margin: 0 12px;
  }

  .ant-tabs-tab-btn {
    white-space: normal;
  }
`;

export const selectAPICSS = css`
  width: 350px;
  .wrap__text div {
    white-space: normal !important;
  }

  .ant-select-item-option-content {
    white-space: normal;
  }
`;

export const MainContainer_Api_css = css`
  .section__header {
    position: fixed;
    width: 100%;
    background: #286ce2 0% 0% no-repeat padding-box;
    z-index: 2;
  }

  .section__api {
    padding-top: 115px;
  }

  .card__api_section {
    background: inherit;
  }

  .card__api_section .ant-card-body {
    padding: 12px 24px;
  }
  .span__nav_current_page {
    font-size: x-large;
    color: white;
  }
  .span__item,
  .ant-breadcrumb-separator {
    color: white;
  }
`;

export const API_CATEGORY_CSS = css`
  .card__api_list {
    width: 90%;
    margin: auto;
    padding: 24px;
    background: inherit;
  }

  .card_grid_item {
    height: 410px;
    padding: 24px;
  }

  .ant-card-cover {
    width: 60%;
    height: 200px;
    margin: auto;
  }
  .h1__api_heading {
    font-size: 1em;
    white-space: normal;
  }

  @media (min-width: 320px) {
    .card_grid_item {
      height: 380px;
      padding: 24px;
    }

    .card__api_list {
      width: 100%;
      margin: auto;
      padding: 0px;
      background: inherit;
    }

    .card__api_list .ant-card-head {
      display: none;
    }

    .ant-card-cover {
      width: 45%;
      height: 130px;
      margin: auto;
    }
  }
  @media (min-width: 480px) {
    .card_grid_item {
      height: 380px;
      padding: 24px;
    }

    .card__api_list {
      width: 100%;
      margin: auto;
      padding: 0px;
      background: inherit;
    }
    .card__api_list .ant-card-head {
      display: none;
    }

    .ant-card-cover {
      width: 60%;
      height: 175px;
      margin: auto;
    }
  }

  @media (min-width: 600px) {
    .card_grid_item {
      height: 410px;
      padding: 24px;
    }

    .card__api_list {
      width: 90%;
      margin: auto;
      padding: 24px;
      background: inherit;
    }

    .card__api_list .ant-card-head {
      display: block;
    }
    .ant-card-cover {
      width: 60%;
      height: 175px;
      margin: auto;
    }
  }
  @media (min-width: 801px) {
    .card_grid_item {
      height: 410px;
      padding: 24px;
    }

    .card__api_list {
      width: 90%;
      margin: auto;
      padding: 24px;
      background: inherit;
    }
    .card__api_list .ant-card-head {
      display: block;
    }
    .ant-card-cover {
      width: 60%;
      height: 180px;
      margin: auto;
    }
  }
  @media (min-width: 1025px) {
    .card_grid_item {
      height: 410px;
      padding: 24px;
    }

    .card__api_list {
      width: 90%;
      margin: auto;
      padding: 0px;
      background: inherit;
    }

    .card__api_list .ant-card-head {
      display: block;
    }
    .ant-card-cover {
      width: 60%;
      height: 100px;
      margin: auto;
    }
  }

  @media (min-width: 1281px) {
    .card_grid_item {
      height: 410px;
      padding: 24px;
    }

    .card__api_list {
      width: 90%;
      margin: auto;
      padding: 0px;
      background: inherit;
    }

    .card__api_list .ant-card-head {
      display: block;
    }

    .ant-card-cover {
      width: 65%;
      height: 150px;
      margin: auto;
    }
  }

  @media screen and (min-width: 1600px) {
    .ant-card-cover {
      width: 60%;
      height: 200px;
      margin: auto;
    }
  }
`;
