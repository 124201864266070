import React, { Fragment } from 'react';
import { Card, Checkbox, Typography, Form } from 'antd';
import { css } from '@emotion/core';

const AgreementCSS = css`
  padding: 24px 0px;
  .terms__container {
    height: 270px;
    overflow-y: scroll;
    font: normal 14px/24px Lato;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  .flex__scroll:hover::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .flex__scroll:hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px green;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  .flex__scroll:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #2453a6;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .bold__p {
    text-align: left;
    font: normal normal bold 14px/24px Lato;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .h3 {
    text-align: left;
    font: normal normal bold 20px/42px Lato;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .terms__card {
    border: 1px solid #c6c6c6;
  }

  .ant-card-actions > li {
    padding: 24px;
    text-align: left;
  }

  /* @media (min-width: 320px) {
  }
  @media (min-width: 480px) {
  }
  @media (min-width: 600px) {
  }
  @media (min-width: 801px) {
  }
  @media (min-width: 1300px) {
  }
  @media (min-width: 1600px) {
  } */
`;

const validateTermsAndCondition = (rule, value) => {
  if (!value) {
    return Promise.reject('Please accept terms and conditions ');
  } else {
    return Promise.resolve();
  }
};

const LicenseAgreement = () => {
  return (
    <section css={AgreementCSS}>
      <Typography.Title className="h4" level={4}>
        Terms & Conditions
      </Typography.Title>
      <Card
        className="terms__card"
        actions={[
          <Fragment key="1">
            <Form.Item
              style={{ margin: 0 }}
              name={['isAgreementSelected']}
              valuePropName="checked"
              rules={[
                {
                  validator: validateTermsAndCondition,
                },
              ]}
            >
              <Checkbox className="bold__p">I have read and accept the terms & conditions</Checkbox>
            </Form.Item>
          </Fragment>,
        ]}
      >
        <div className="terms__container flex__scroll">
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas sapiente
              illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam harum
              iure eos.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas sapiente
              illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam harum
              iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas
              sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam
              harum iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in,
              quas sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita
              quisquam harum iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium
              debitis in, quas sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi
              expedita quisquam harum iure eos.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas sapiente
              illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam harum
              iure eos.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas sapiente
              illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam harum
              iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas
              sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam
              harum iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in,
              quas sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita
              quisquam harum iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium
              debitis in, quas sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi
              expedita quisquam harum iure eos.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas sapiente
              illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam harum
              iure eos.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas sapiente
              illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam harum
              iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in, quas
              sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita quisquam
              harum iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium debitis in,
              quas sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi expedita
              quisquam harum iure eos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora ut laudantium
              debitis in, quas sapiente illum accusantium nesciunt dolores facere suscipit aperiam esse molestiae modi
              expedita quisquam harum iure eos.
            </p>
          </div>
        </div>
      </Card>
    </section>
  );
};

export default LicenseAgreement;
