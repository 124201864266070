import React from 'react';
import AppLayout from './components/AppLayout';
import AppRoutes from './containers/AppRoutes';

function App() {
  return (
    <AppLayout>
      <AppRoutes />
    </AppLayout>
  );
}

export default App;
