import React from 'react';
import { bool } from 'prop-types';
import './progressBar.css';

export default function ProgressBar(props) {
  const bars = [1, 2, 3, 4, 5];
  const barColor = props.blueColor ? 'blue' : 'white';
  return (
    <div className="progressBar">
      {bars.map(x => (
        <div key={x} className={`rect${x} ${barColor}`} />
      ))}
    </div>
  );
}

ProgressBar.propTypes = {
  blueColor: bool,
};
