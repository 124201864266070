import React, { Suspense } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import ProgressBar from './../components/ProgressBar';
import LazyConfirmationModule from './Registration/Confirmation';
import LazyLandingModule from './Landing/Landing';
//const LazyLandingModule = lazy(() => import('./Landing/Landing'));
import ApiService from './ApiService/ApiService';
import ApiOverviewScrollerHOC from './ApiService/ApiOverviewScrollerHOC';
import ApiDetailsComponent from './Landing/ApiDetailsComponent';
import GetStarted from './Registration/GetStarted';
import Thankyou from './Registration/Thankyou';
import Expired from './Registration/Expired';
import ResendEmail from './Registration/ResendEmail';
import AlreadyVerified from './Registration/AlreadyVerified';
import RegistrationDone from './Registration/RegistrationDone';
import EmailConfirmation from './Registration/EmailConfirmation';
import ErrorPage from './Registration/ErrorPage';

function NoMatch() {
  const location = useLocation();
  return (
    <div>
      <h3>
        Route not match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default function AppRoutes() {
  return (
    <Suspense fallback={<ProgressBar />}>
      <Switch>
        <Route exact path="/explore/api-service/:category">
          <ApiDetailsComponent />
        </Route>
        <Route path="/explore/api-service">
          <ApiOverviewScrollerHOC>
            <ApiService />
          </ApiOverviewScrollerHOC>
        </Route>
        <Route exact path="/register">
          <GetStarted />
        </Route>
        <Route exact path="/register/confirmation">
          <LazyConfirmationModule />
        </Route>
        <Route exact path="/register/thankyou">
          <Thankyou />
        </Route>
        <Route exact path="/register/expired/:token">
          <Expired />
        </Route>
        <Route exact path="/register/resent">
          <ResendEmail />
        </Route>
        <Route exact path="/register/verified">
          <AlreadyVerified />
        </Route>
        <Route exact path="/register/registered">
          <RegistrationDone />
        </Route>
        <Route exact path="/register/confirm/:token">
          <EmailConfirmation />
        </Route>
        <Route exact path="/register/error">
          <ErrorPage />
        </Route>
        <Route exact path="/explore">
          <LazyLandingModule />
        </Route>
        <Route path="/">
          <Redirect to="explore" />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Suspense>
  );
}
