import React, { Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { Menu, Drawer } from 'antd';
import { css } from '@emotion/core';

const { SubMenu } = Menu;
import { HashLink as Link } from 'react-router-hash-link';

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -220;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export const MobileDrawerCss = css`
  height: 100%;
  overflow-y: scroll;

  .menu__item {
    padding: 10px;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 45px;
    font-size: 12px;
    color: black;
    font-weight: 500;
    line-height: 23px;
    list-style-position: inside;
    list-style-type: disc;
    display: inline-flex;
    align-items: center;
    white-space: break-spaces;
    padding-left: 45px !important;
  }

  .ant-drawer-body {
    padding: 24px 0px;
  }

  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 40px !important;
  }

  .img__api {
    width: 15%;
  }
  .ant-drawer-content {
    height: 100%;
    overflow-y: scroll;
  }
`;

const MobileDrawer = props => {
  const { agencyApiData, commercialApiData, visible, closeDrawer } = props;

  return (
    <Fragment>
      <div>
        <Drawer
          placement="right"
          closable={false}
          onClose={closeDrawer}
          visible={visible}
          key="right"
          css={MobileDrawerCss}
          width={320}
        >
          <Menu mode="inline">
            <Menu.Item key="home">
              <Link to="/explore" onClick={closeDrawer}>
                Home
              </Link>
            </Menu.Item>
            <SubMenu key="api_service" title="API Service">
              <SubMenu key="agency_api" title={agencyApiData?.moduleTitle}>
                {agencyApiData?.ListAssoc?.map(api => {
                  return (
                    <Menu.Item key={api?.name} className="menu__item">
                      <span>
                        <img
                          className="img__api"
                          src={require(`./../../common/img/api-services/${api?.name}_service.svg`).default}
                        />
                      </span>
                      <span>
                        <Link
                          onClick={closeDrawer}
                          to={`/explore/api-service/agency_api#${api?.name}`}
                          scroll={el => scrollWidthOffset(el)}
                        >
                          {api?.heading}
                        </Link>
                      </span>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
              <SubMenu key="commercial_api" title={commercialApiData?.moduleTitle}>
                {commercialApiData?.ListAssoc?.map(api => {
                  return (
                    <Menu.Item key={api?.name} className="menu__item">
                      <span>
                        <img
                          className="img__api"
                          src={require(`./../../common/img/api-services/${api?.name}_service.svg`).default}
                        />
                      </span>
                      <span>
                        <Link
                          onClick={closeDrawer}
                          to={`/explore/api-service/commercial_api#${api?.name}`}
                          scroll={el => scrollWidthOffset(el)}
                        >
                          {api?.heading}
                        </Link>
                      </span>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            </SubMenu>
          </Menu>
        </Drawer>
      </div>
    </Fragment>
  );
};

MobileDrawer.propTypes = {
  agencyApiData: object,
  commercialApiData: object,
  visible: bool,
  closeDrawer: func,
};

export default MobileDrawer;
