import React, { Fragment, useState, useEffect } from 'react';

import { Steps, Form, Typography, Card, Button, notification } from 'antd';
import { GetStartedCss, greetingCard, GetStartedLayoutCss } from './GetStarted.style';
import { ArrowLeftOutlined } from '@ant-design/icons';
import LegalEntityForm from './LegalEntityForm';
import { useHistory } from 'react-router-dom';
import {
  Personas,
  PARTNER_EXISTS_409,
  BROKER_USER_TIN_CONFLICT_409,
  INVALID_USER_DETAILS_404,
  SOMETHING_WENT_WRONG,
  CANCEL_MODAL_DESCRIPTION,
} from './../../common/constants/AppConstants';
import { getApiList, registerPartner } from './../../services/registration.service';
import ApiSelection from './ApiSelection';
import ModalWrapper from './../../components/ModalWrapper/ModalWrapper';
import WarningNotification from 'Client/components/WarningNotification/WarningNotification';
import Overview from './Overview';
import { array } from 'prop-types';

const { Step } = Steps;
const GetStarted = () => {
  const history = useHistory();
  const [resetFlag, setResetFlag] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [legalInfoForm] = Form.useForm();
  const [selectApiForm] = Form.useForm();
  const [apiList, setApiList] = useState({ status: 'pending', data: [], error: null });
  const legalEntityInitialValue = {
    type: Personas.BROKER,
  };

  const [apiFormData, setApiFormData] = useState([]);

  const agencysAPIArray = [];
  const commercialAPIArray = [];

  // reform this code
  const agencyList = [
    'COMM_API',
    'BOB_API',
    'APP_DETAILS_API',
    'OPTY_MGMT_API',
    'BENEFIT_DETAILS_API',
    'BROKER_NEWS_FEED_API',
    'RENEWAL_API',
  ];
  const commercialList = [
    'APP_SUBMISSION_API',
    'APP_STATUS_API',
    'DOC_UPLOAD_API',
    'GRP_STRUCT_API',
    'MBR_ENROLL_API',
    'MBR_MNTNC_API',
    'RENEW_PKG_API',
    'GEN_UPLOAD_API',
    'QUOTE_API',
    'CENSUS_API',
  ];

  apiFormData.map(api => {
    const apiId = api.apiId;
    if (agencyList.includes(apiId)) {
      agencysAPIArray.push(api.apiName);
    }
    if (commercialList.includes(apiId)) {
      commercialAPIArray.push(api.apiName);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getAPIList();
  }, []);

  const getAPIList = () => {
    getApiList()
      .then(res => {
        setApiList({ data: res?.data?.results?.data, status: 'success', error: null });
      })
      .catch(err => {
        setApiList({ data: [], status: 'error', error: err });
      });
  };

  const buildRegistrationRequest = (legalEntityData, selectApiFormData) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return {
      companyName: legalEntityData?.companyName,
      // agreementAccepted: true,
      persona: {
        type: legalEntityData?.type?.toLowerCase(),
        bpUserId: legalEntityData.type === Personas.BROKER ? legalEntityData?.bpUserId || undefined : undefined,
        bpTaxId: legalEntityData.type === Personas.BROKER ? legalEntityData?.bpTaxId || undefined : undefined,
        firstName: legalEntityData?.firstName,
        lastName: legalEntityData?.lastName,
        emailAddress: legalEntityData?.email,
        phoneNumber: legalEntityData?.phoneNumber?.replace(phoneRegex, '$1$2$3'),
        address1: legalEntityData?.addressLine1,
        address2: legalEntityData?.addressLine2,
        zipCode: legalEntityData?.zipCode,
        city: legalEntityData?.city,
        state: legalEntityData?.state,
      },
      apis: selectApiFormData?.map(api => {
        return { apiId: api?.apiId };
      }),
    };
  };

  const finalSubmission = selectApiFormData => {
    registerPartner(buildRegistrationRequest(legalInfoForm?.getFieldValue(), selectApiFormData))
      .then(res => {
        if (res?.data?.status == 'SUCCESS') {
          history.push('/register/registered', { update: legalInfoForm?.getFieldValue().email });
        } else {
          triggerErrorNotification('Registration', SOMETHING_WENT_WRONG);
        }
      })
      .catch(err => {
        handleException(err);
      });
  };

  const handleException = err => {
    if (err?.response?.status === 409) {
      if (
        'partner_exists_409' === err?.response?.data?.data?.code ||
        'email_already_exists_409' === err?.response?.data?.data?.code
      ) {
        triggerErrorNotification('Registration', PARTNER_EXISTS_409);
      } else if ('broker_user_tin_conflict_409' === err?.response?.data?.data?.code) {
        triggerErrorNotification('Registration', BROKER_USER_TIN_CONFLICT_409);
      }
    } else if (err?.response?.status === 404) {
      if ('broker_not_found_404' === err?.response?.data?.data?.code) {
        triggerErrorNotification('Registration', INVALID_USER_DETAILS_404);
      }
    } else {
      triggerErrorNotification('Registration', SOMETHING_WENT_WRONG);
    }
  };

  const triggerErrorNotification = (message, description) => {
    document.getElementById('registration').scrollIntoView({ behavior: 'smooth', block: 'start' });
    notification.error({
      message,
      description,
      style: {
        width: '60vw',
      },
    });
  };

  const modalOnCancelButtonHandler = () => {
    setResetFlag(false);
  };

  const openWarningModal = () => {
    legalInfoForm?.isFieldsTouched() ? history.push('/explore') : setResetFlag(true);
  };

  const modalOnOkButtonHandler = () => {
    history.push('/explore');
  };

  const onFinish = () => {
    const formData = legalInfoForm?.getFieldValue();
    if (validateForm(formData)) {
      window.scrollTo(0, 0);
      setCurrentStep(1);
    }
  };

  const validateForm = values => {
    if (values?.type === Personas.BROKER) {
      if (values?.bpUserId || values?.bpTaxId) {
        return true;
      } else {
        notification.error({
          message: 'LegalEntity',
          description: 'Please enter your Broker Portal Username /Tax ID',
        });
        return false;
      }
    }
    return true;
  };

  return (
    <Fragment>
      <section id="registration" css={GetStartedLayoutCss}>
        <div>
          <Card css={greetingCard} bordered={false}>
            <div>
              <Button
                type="link"
                block
                className="btn-link"
                onClick={openWarningModal}
                id="back-to-admin-update-partner"
              >
                <ArrowLeftOutlined style={{ color: 'white' }} /> <span style={{ color: 'white' }}>Back</span>
              </Button>
            </div>
            <Typography.Title level={3} className="description heading-underline">
              Register
            </Typography.Title>
          </Card>
        </div>

        <section id="main-section" css={GetStartedCss}>
          <Card bordered={false} className="registration__card">
            <Steps size="small" current={currentStep} labelPlacement="vertical">
              <Step title="Legal Entity" />
              <Step title="Select API" />
              <Step title="Confirm" />
            </Steps>

            {currentStep === 0 && (
              <Card
                bordered={false}
                style={{ display: currentStep === 0 ? 'block' : 'none' }}
                title={
                  <Typography.Title level={3} className="legal__heading">
                    Please tell us about yourself so we can recommend the right APIs for your business.
                  </Typography.Title>
                }
              >
                <Form
                  name="getStartedForm"
                  form={legalInfoForm}
                  initialValues={legalEntityInitialValue}
                  layout="vertical"
                  onFinish={onFinish}
                  hideRequiredMark={true}
                  scrollToFirstError
                >
                  <LegalEntityForm legalInfoForm={legalInfoForm} selectApiForm={selectApiForm} />
                </Form>
              </Card>
            )}

            {currentStep === 1 && (
              <section>
                <ApiSelection
                  selectApiForm={selectApiForm}
                  apiList={apiList?.data}
                  partnerType={legalInfoForm.getFieldValue('type') || Personas.BROKER}
                  backToLegalEntity={() => {
                    setCurrentStep(0);
                  }}
                  nextToOverview={formValues => {
                    setApiFormData(formValues);
                    setCurrentStep(2);
                  }}
                  openWarningModal={openWarningModal}
                />
              </section>
            )}
            {currentStep === 2 && (
              <Overview
                agency={agencysAPIArray}
                commercial={commercialAPIArray}
                initialValues={legalInfoForm?.getFieldValue()}
                apiFormDataLength={apiFormData?.length}
                backToApiSelection={() => {
                  setCurrentStep(1);
                }}
                backToLegalEntity={() => {
                  setCurrentStep(0);
                }}
                finalSubmission={finalSubmission}
                openWarningModal={openWarningModal}
                selectApiForm={apiFormData}
              ></Overview>
            )}
            {currentStep === 3 && <Form form={selectApiForm}></Form>}
          </Card>
        </section>

        <ModalWrapper visible={resetFlag}>
          <WarningNotification
            okBtnText={'Yes'}
            cancelBtnText={'Cancel'}
            cancelButtonHandler={modalOnCancelButtonHandler}
            okButtonHandler={modalOnOkButtonHandler}
            description={CANCEL_MODAL_DESCRIPTION}
          />
        </ModalWrapper>
      </section>
    </Fragment>
  );
};

export default GetStarted;

Overview.propTypes = {
  agencysAPIArray: array,
  commercialAPIArray: array,
};
