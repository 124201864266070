import React, { Fragment, useEffect, useContext, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AppContext } from './../../Contexts/AppContext';
import { object } from 'prop-types';
import { Card, Row, Col, Typography, Layout, Menu } from 'antd';
import BreadCrumbWrapper from './../ApiService/BreadCrumbWrapper';
const { Content, Sider } = Layout;
import { Link } from 'react-scroll';
import { ApiDetailCSS, ApiLayoutCss, BreadCrumbHeaderCss, SideBarCss } from './ApiDetailsComponent.style';

const ApiDetail = props => {
  const { apiData } = props;
  return (
    <Fragment>
      {apiData?.ListAssoc?.map((api, index) => {
        return (
          <section id={api?.name} key={api?.name} className="section__api_container">
            <Row align="middle" justify="space-between" gutter={[24, 24]}>
              <Col
                xs={{ span: 24, order: 0 }}
                sm={{ span: 24, order: 0 }}
                md={{ span: 24, order: 0 }}
                lg={{ span: 8, order: index % 2 === 0 ? 0 : 1 }}
                xl={{ span: 8, order: index % 2 === 0 ? 0 : 1 }}
              >
                <Card bordered={false} className="card__api">
                  <img src={'/public/api/landing/fetch/' + api.name + '_detail'} className="image__grid_item" />
                </Card>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 24, order: 1 }}
                lg={{ span: 16, order: index % 2 === 0 ? 1 : 0 }}
                xl={{ span: 16, order: index % 2 === 0 ? 1 : 0 }}
              >
                <Card bordered={false} className="card__api_col2">
                  <Card.Meta
                    title={
                      <Typography.Title level={2} className="api__heading">
                        {api?.heading}
                      </Typography.Title>
                    }
                    description={
                      <Typography.Title level={4} className="api__title">
                        <span dangerouslySetInnerHTML={{ __html: api?.title }}></span>
                      </Typography.Title>
                    }
                  />

                  <div className="api__description" dangerouslySetInnerHTML={{ __html: api?.copy }}></div>
                </Card>
              </Col>
            </Row>
          </section>
        );
      })}
    </Fragment>
  );
};

ApiDetail.propTypes = {
  apiData: object,
};

const ApiDetailsComponent = () => {
  const { wraSlotList } = useContext(AppContext);
  const { category } = useParams();
  const [state, setState] = useState();
  const location = useLocation();
  const [apiRoute, setApiRoute] = useState();

  useEffect(() => {
    const apiWidgetContent = wraSlotList?.find(data => data?.name === 'api-cards-marketing-list')?.ListAssoc || [];
    const agencyApi = apiWidgetContent?.find(data => data?.name === 'partner_agency_mgmt_apis');
    const commercialApi = apiWidgetContent?.find(data => data?.name === 'partner_comm_grp_subm_n_mbr_mgmt_apis');
    setState({
      data: { agencyApi, commercialApi },
      status: 'success',
    });
    const routes = [
      {
        path: '/explore',
        breadcrumbName: 'Home',
      },
      {
        path: '/explore/api-service',
        breadcrumbName: 'API Services',
      },
    ];

    if (category === 'commercial_api') {
      routes?.push({
        path: 'commercial_api',
        breadcrumbName: 'Commercial Group Submission & Member Management',
      });
    } else {
      routes?.push({
        path: 'agency_api',
        breadcrumbName: 'Agency Management API',
      });
    }
    setApiRoute(routes);
  }, [location]);

  return (
    <Fragment>
      <section id="section__header" className="section__header" css={BreadCrumbHeaderCss}>
        <BreadCrumbWrapper routes={apiRoute} />
      </section>

      <Layout className="site-layout-background site__layout" css={ApiLayoutCss}>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            top: '177px',
            left: 0,
          }}
          width={300}
          breakpoint="lg"
          collapsedWidth="0"
          className="site-layout-background site__sidebar"
          css={SideBarCss}
        >
          <Menu mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} style={{ height: '100%' }}>
            {category === 'agency_api' &&
              state?.data?.agencyApi?.ListAssoc?.map(api => {
                return (
                  <Menu.Item key={api?.name} className="menu__item">
                    <Link
                      offset={-220}
                      activeClass="active"
                      to={api?.name}
                      spy={true}
                      smooth={'easeOutCubic'}
                      duration={700}
                      className="menu__link"
                    >
                      {api?.heading}
                    </Link>
                  </Menu.Item>
                );
              })}
            {category === 'commercial_api' &&
              state?.data?.commercialApi?.ListAssoc?.map(api => {
                return (
                  <Menu.Item key={api?.name} className="menu__item">
                    <Link
                      offset={-220}
                      activeClass="active"
                      to={api?.name}
                      spy={true}
                      smooth={'easeOutCubic'}
                      duration={700}
                      className="menu__link"
                    >
                      {api?.heading}
                    </Link>
                  </Menu.Item>
                );
              })}
          </Menu>
        </Sider>
        <Content css={ApiDetailCSS}>
          <Fragment>{category === 'agency_api' && <ApiDetail apiData={state?.data?.agencyApi} />}</Fragment>
          <Fragment>{category === 'commercial_api' && <ApiDetail apiData={state?.data?.commercialApi} />}</Fragment>
        </Content>
      </Layout>
    </Fragment>
  );
};

export default ApiDetailsComponent;
