import { css } from '@emotion/core';
export const HeaderCss = css`
  .hamburger__menu span {
    font-size: 30px;
    line-height: 3;
  }
  /* .img-logo {
    width: 550px;
  }
  @media (max-width: 1024px) {
    .img-logo {
      width: 450px;
    }
  }

  @media (max-width: 768px) {
    .img-logo {
      width: 250px;
    }
  }

  @media screen and (max-width: 480px) {
    .img-logo {
      width: 260px;
    }
  } */

  .menu__item {
    font: normal normal bold 18px/52px Lato;
    letter-spacing: -0.45px;
    color: #37475a;
    opacity: 1;
  }

  .ant-menu-item-selected {
    font: normal normal bold 18px/52px Lato;
    letter-spacing: -0.45px;
    color: #286ce2;
    opacity: 1;
  }
  &.header__container {
    background: white;
    position: fixed;
    z-index: 9;
    width: 100%;
  }

  .align-center {
    text-align: center;
  }

  .header__login_btn {
    height: 40px;
    border-radius: 0px;
    color: #286ce2;
    border: 1px solid #286ce2;
  }
`;

export const userDropDownMenuCSS = {
  width: '1000px',
  padding: '24px',
  position: 'fixed',
};

export const AgencyListCSS = css`
  .agency__api-type {
    background: inherit;
    text-align: left;
    font-size: 10px;
    font-weight: 500;
  }

  .api__li_option {
    cursor: pointer;
  }
  .agency__api-img {
    height: 25px;
    width: 25px;
    margin-top: 5px;
  }

  .agency__api-name {
    background: inherit;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
  }

  li {
    list-style: none;
  }

  .agency__li {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-gap: 20px;
  }

  .ant-card-body {
    padding: 24px 0px;
  }

  .ant-card-head-title {
    padding-left: 20px;
    font-weight: 600;
    padding-bottom: 0px;
    font-size: 15px;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

export const CommercialListCSS = css`
  .ant-card-body {
    padding: 24px 0px;
  }

  li {
    list-style: none;
  }

  .ant-card-head-title {
    padding-left: 20px;
    font-weight: 600;
    padding-bottom: 0px;
    font-size: 15px;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .commercial__api-name {
    background: inherit;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
  }

  .commercial__api-type {
    background: inherit;
    text-align: left;
    font-size: 10px;
    font-weight: 500;
  }

  .commercial__api-img {
    height: 25px;
    width: 25px;
    margin-top: 5px;
  }

  .commercial__li {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 20px;
  }
`;

export const DividerCSS = css`
  .ant-col-lg-24 {
    border-top: 1px solid lightgrey;
  }

  .ant-col-lg-8 {
    border-right: 1px solid lightgrey;
  }
`;

export const BrowseAllAPIsButtonCSS = css`
  .browse-all-apis-btn {
    margin: 15px 10px 10px 0px;
    background: #e95a3c;
    border: none;
    color: white;
    border-radius: 0px;
    font-family: Lato;
    float: right;
    height: 40px;
  }
`;

export const CardCSS = css`
  border: 1px solid lightgrey;
`;

export const APIListMobileViewCSS = css`
  @media (max-width: 768px) {
    .hamburger_menu__api-name {
      font-family: Montserrat;
      margin-left: 10px;
      background: inherit;
      text-align: left;
      font-size: 12px;
      font-weight: 700;
    }

    .hamburger_menu__api-img {
      margin-top: 5px;
      height: 25px;
      width: 25px;
    }

    .hamburger_menu___item span {
      padding: 0 5px;
    }
  }
`;

export const menuBar = css`
  line-height: 35px !important;
  text-align: left;
  font-weight: 400;
  font-family: lato;
  margin-bottom: 5px;
`;
export const HamburgerCSS = css`
  .barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 15px;
    margin-right: 15px;
    display: none;
    background: none;
    border: none;
  }

  .barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: #1890ff;
    position: relative;
  }

  .barsBtn:after,
  .barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: #1890ff;
  }

  .barsBtn:after {
    top: auto;
    bottom: -6px;
  }

  .barsMenu > span {
    display: block;
  }

  @media (max-width: 768px) {
    .barsMenu {
      display: inline-block;
    }
    .ant-drawer-body {
      padding: 24px 0;
    }
  }
  @media (width: 768px) {
    .barsMenu {
      display: inline-block;
      float: none;
    }
    .ant-drawer-body {
      padding: 24px 0;
    }
  }
`;
