import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { node } from 'prop-types';
import CSSCacheProvider from './CSSCacheProvider';
import { AppContentProvider } from './AppContext';

const basename = '/public';

const queryConfig = {
  useErrorBoundary: false,
  refetchAllOnWindowFocus: false,
  refetchOnMount: false,
  retry: false,
};

function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <CSSCacheProvider value={{ key: 'app' }}>
        <Router basename={basename}>
          <AppContentProvider>{children}</AppContentProvider>
        </Router>
      </CSSCacheProvider>
    </ReactQueryConfigProvider>
  );
}

AppProviders.propTypes = {
  children: node,
};

export { AppProviders };
