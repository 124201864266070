import React, { Fragment } from 'react';
import { string, object } from 'prop-types';
import { Typography, Card, Row, Col, Space } from 'antd';
import ROUNDED_ARROW from './../../common/img/api/rounded-right-arrow.svg';
import { HashLink as Link } from 'react-router-hash-link';
import { scroller } from 'react-scroll';

const ApiWidget = props => {
  const { apiData, category } = props;

  return (
    <Fragment>
      {apiData && (
        <section className="section__api_widget">
          <Card bordered={false} className="card__api_widget">
            <div className="card__wrapper">
              <Row gutter={[24, 24]} justify="space-around" align="middle">
                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                  <Typography.Title className="typography__api_heading">{apiData?.moduleTitle}</Typography.Title>
                </Col>

                {apiData?.ListAssoc?.slice(0, 3)?.map(api => {
                  return (
                    <Col sm={12} xs={12} md={12} lg={12} xl={12} key={api?.name}>
                      <Card hoverable className="card__api_list">
                        <Link
                          to={`/explore/api-service/${category}#${api?.name}`}
                          scroll={() =>
                            scroller.scrollTo(`${api?.name}`, { offset: -220, smooth: 'easeInOutCirc', duration: 500 })
                          }
                        >
                          <Space direction="vertical">
                            <span>
                              <img
                                className="image__card_shot_list"
                                alt="example"
                                src={'/public/api/landing/fetch/' + api.name}
                              />
                            </span>
                            <Typography.Title className="typography__api_title">{api?.heading}</Typography.Title>
                          </Space>
                        </Link>
                      </Card>
                    </Col>
                  );
                })}
                <Col sm={12} xs={12} md={12} lg={12} xl={12} key="browseAllApi">
                  <Card hoverable className="card__api_list browse_all">
                    <Link
                      to={`/explore/api-service#${category}`}
                      scroll={() =>
                        scroller.scrollTo(`${category}`, { offset: -200, smooth: 'easeInOutCirc', duration: 500 })
                      }
                    >
                      <Space direction="vertical">
                        <span>
                          <img className="browse-all-logo" alt="example" src={ROUNDED_ARROW} />
                        </span>
                        <Typography.Title className="typography__api_title link__heading">
                          Browse all APIs
                        </Typography.Title>
                      </Space>
                    </Link>
                  </Card>
                </Col>
              </Row>
            </div>
          </Card>
        </section>
      )}
    </Fragment>
  );
};

ApiWidget.propTypes = {
  apiData: object,
  category: string,
};

export default ApiWidget;
