import { css } from '@emotion/core';
export const ApiSelectionCss = css`
  .api-info {
    box-shadow: 2px 2px 4px #00000014;
    border: 1px solid #eaeaea;
    margin-top: 14px;
  }

  .upload-btn {
    background: #37475a 0% 0% no-repeat padding-box;
    color: white;
  }

  .txt__center {
    text-align: center;
  }

  .h6__description {
    color: #666666;
    font-weight: 500;
    font-size: 16px;
    font-family: Lato;
  }

  .api-img {
    text-align: center;
  }

  .app-sub-main-row {
    margin: auto !important;
  }

  .sub-check {
    font-family: Lato;
    font-size: 14px;
    color: #333333;
  }

  .select__all_chk_box {
    margin-left: 25px;
  }

  .tag-btn {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
  }
  .notification-btn {
    font-weight: 400;
    border-radius: 6px;
    min-height: 20px;
    height: 25px;
    font-size: 14px !important;
    padding: 3px 15px 5px 10px;
    background: #286ce2;
    border-color: #286ce2;
    font-family: Lato;
    color: #ffffff;
    right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .notification-img {
    padding-right: 10px;
    padding-top: 2px;
    padding-left: 8px;
  }

  @media (min-width: 320px) {
    .h1__title {
      font-size: 16px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 16px;
      font-family: Montserrat;
    }
    .select__all {
      text-align: right;
    }
    .app-sub-main-row {
      width: 100%;
    }
  }
  @media (min-width: 480px) {
    .h1__title {
      font-size: 16px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 16px;
      font-family: Montserrat;
    }
    .select__all {
      text-align: right;
    }
    .app-sub-main-row {
      width: 100%;
    }
  }
  @media (min-width: 600px) {
    .h1__title {
      font-size: 18px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 16px;
      font-family: Montserrat;
    }

    .select__all {
      text-align: right;
    }
    .app-sub-main-row {
      width: 100%;
    }
  }
  @media (min-width: 801px) {
    .h1__title {
      font-size: 24px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 22px;
      font-family: Montserrat;
    }

    .select__all {
      text-align: left;
    }
    .app-sub-main-row {
      width: 70%;
    }
  }
  @media (min-width: 1025px) {
    .h1__title {
      font-size: 26px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 22px;
      font-family: Montserrat;
    }

    .select__all {
      text-align: center;
    }
    .app-sub-main-row {
      width: 70%;
    }
  }
  @media (min-width: 1281px) {
    .h1__title {
      font-size: 26px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 22px;
      font-family: Montserrat;
    }
    .select__all {
      text-align: center;
    }
    .app-sub-main-row {
      width: 70%;
    }
  }
`;
