import React, { Fragment, useEffect } from 'react';
import { node } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { animateScroll, scroller } from 'react-scroll';

const ApiOverviewScrollerHOC = ({ children }) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '#commercial_api') {
      setTimeout(scrollToCommercialApiSection, 0);
    } else {
      scrollToAgencyApiSection();
    }
  }, []);

  const scrollToAgencyApiSection = () => {
    animateScroll.scrollToTop({ smooth: 'easeInOutCirc', duration: 500 });
  };

  const scrollToCommercialApiSection = () => {
    scroller.scrollTo('commercial_api', { offset: -200, smooth: 'easeInOutCirc', duration: 500 });
  };

  return <Fragment>{children}</Fragment>;
};

ApiOverviewScrollerHOC.propTypes = {
  children: node,
};

export default ApiOverviewScrollerHOC;
