import { css } from '@emotion/core';
export const CarousalCss = css`
  .main-wrapper {
    width: 80%;
    margin: auto;
  }

  .api__col {
    padding: 0px !important;
  }

  &.main_upcoming_container {
    height: 75vh;
  }

  .div__upcoming_container {
    position: relative;
    top: 15%;
  }

  .bg_white {
    background: white;
  }

  .h1__upcoming_api_title {
    text-align: center;
    width: 60%;
    margin: auto;
  }

  .div__slide {
    padding: 24px;
  }

  .slick-prev::before {
    color: black !important;
    font-size: 35px;
    left: -30px;
  }
  .slick-next::before {
    color: black !important;
    font-size: 35px;
    right: -15px;
  }

  .card__slide {
    border: 1px solid whitesmoke;
    box-shadow: 1px 2px 5px lightgrey;
  }

  @media (min-width: 320px) {
    &.main_upcoming_container {
      height: 80vh;
    }
    .h1__api_heading {
      font: normal normal 600 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .h1__upcoming_api_title {
      width: 60%;
      font: normal normal bold 26px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .h1__api_description {
      font: normal normal normal 16px/30px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .api__image {
      width: 100%;
      height: auto;
    }
    .div__upcoming_container {
      top: 10%;
    }

    .slick-prev {
      left: -7px;
    }

    .slick-next {
      right: 12px;
    }

    .slick-prev::before {
      color: black !important;
      font-size: 40px;
    }
    .slick-next::before {
      color: black !important;
      font-size: 40px;
    }

    .main-wrapper {
      width: 85%;
      margin: auto;
    }
  }
  @media (min-width: 480px) {
    &.main_upcoming_container {
      height: 90vh;
    }
    .h1__api_heading {
      font: normal normal 600 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .h1__upcoming_api_title {
      width: 60%;
      font: normal normal bold 26px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .h1__api_description {
      font: normal normal normal 16px/30px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .api__image {
      width: 100%;
      height: auto;
    }
    .div__upcoming_container {
      top: 10%;
    }

    .slick-prev {
      left: -7px;
    }

    .slick-next {
      right: 12px;
    }

    .slick-prev::before {
      color: black !important;
      font-size: 40px;
    }
    .slick-next::before {
      color: black !important;
      font-size: 40px;
    }

    .main-wrapper {
      width: 85%;
      margin: auto;
    }
  }

  @media (min-width: 600px) {
    //ipad device
    &.main_upcoming_container {
      height: 70vh;
    }
    .h1__api_heading {
      font: normal normal 600 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .h1__upcoming_api_title {
      width: 60%;
      font: normal normal bold 26px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .h1__api_description {
      font: normal normal normal 16px/30px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .api__image {
      width: 100%;
      height: auto;
    }
    .div__upcoming_container {
      top: 12%;
    }

    .slick-prev {
      left: -34px;
    }

    .slick-next {
      right: -14px;
    }

    .slick-prev::before {
      color: black !important;
      font-size: 40px;
    }
    .slick-next::before {
      color: black !important;
      font-size: 40px;
    }

    .main-wrapper {
      width: 85%;
      margin: auto;
    }
  }
  @media (min-width: 801px) {
    //ipad device
    &.main_upcoming_container {
      height: 50vh;
    }
    .h1__api_heading {
      font: normal normal 600 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .h1__upcoming_api_title {
      width: 60%;
      font: normal normal bold 26px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .h1__api_description {
      font: normal normal normal 16px/30px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .api__image {
      width: 100%;
      height: auto;
    }
    .div__upcoming_container {
      top: 12%;
    }

    .slick-prev {
      left: -34px;
    }

    .slick-next {
      right: -14px;
    }

    .slick-prev::before {
      color: black !important;
      font-size: 40px;
    }
    .slick-next::before {
      color: black !important;
      font-size: 40px;
    }

    .main-wrapper {
      width: 85%;
      margin: auto;
    }
  }
  @media (min-width: 1025px) {
    //ipad device
    &.main_upcoming_container {
      height: 65vh;
    }
    .h1__api_heading {
      font: normal normal 600 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .h1__upcoming_api_title {
      width: 60%;
      font: normal normal bold 26px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .h1__api_description {
      font: normal normal normal 16px/30px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .api__image {
      width: 100%;
      height: auto;
    }
    .div__upcoming_container {
      top: 12%;
    }

    .slick-prev {
      left: -34px;
    }

    .slick-next {
      right: -14px;
    }

    .slick-prev::before {
      color: black !important;
      font-size: 40px;
    }
    .slick-next::before {
      color: black !important;
      font-size: 40px;
    }

    .main-wrapper {
      width: 85%;
      margin: auto;
    }
  }
  @media (min-width: 1400px) {
    //ipad device
    &.main_upcoming_container {
      height: 70vh;
    }
    .h1__api_heading {
      text-align: left;
      font: normal normal 600 32px/39px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .h1__upcoming_api_title {
      padding: 24px 0;
      width: 60%;
      text-align: center;
      font: normal normal bold 36px/44px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .h1__api_description {
      text-align: left;
      font: normal normal normal 22px/42px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .api__image {
      width: 90%;
      height: 340px;
    }
    .div__upcoming_container {
      top: 10%;
    }

    .slick-prev {
      left: -34px;
    }

    .slick-next {
      right: -14px;
    }

    .slick-prev::before {
      color: black !important;
      font-size: 40px;
    }
    .slick-next::before {
      color: black !important;
      font-size: 40px;
    }

    .main-wrapper {
      width: 85%;
      margin: auto;
    }
  }
`;
