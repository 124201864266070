import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Divider, Row, Col } from 'antd';
import { css } from '@emotion/core';
import VerifiedImg from './../../common/img/Verified.svg';

export const greetingCardHeader = css`
  padding: 3em 3.8em 2em;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const cssContent = css`
  background: transparent linear-gradient(90deg, #58abdf 0%, #286ce2 100%) 0% 0% no-repeat padding-box;
  background-size: 100% 200px;
  padding: 0 100px;
  margin-bottom: 4em;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const alreadyVerifiedCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
  }

  span.ant-typography {
    font-family: Montserrat;
    font-weight: 500;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: -0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.23;
  }

  #resend-email-btn {
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }
`;

const AlreadyVerified = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div css={cssContent}>
        <Card css={greetingCardHeader} bordered={false}></Card>
        <Card css={alreadyVerifiedCss}>
          <div className="ant-result ant-result-info">
            <div className="ant-result-icon">
              <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                <img src={VerifiedImg}></img>
              </span>
            </div>
            <div className="ant-result-title">
              <Fragment>
                <Typography.Title>You have already verified your email address with us.</Typography.Title>
              </Fragment>
            </div>
            <div className="ant-result-extra">
              <Fragment>
                <Row gutter={24} justify="center">
                  <Col md={24}>
                    <Typography.Text>
                      Please wait while our business team reviews your details and get back to you soon.
                    </Typography.Text>
                    <Divider style={{ margin: '20px' }} />
                  </Col>
                </Row>
              </Fragment>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default AlreadyVerified;
