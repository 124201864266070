import { func, object, array, int } from 'prop-types';
import React, { Fragment } from 'react';
import { Card, Button, Row, Col, Divider } from 'antd';
import { OverviewCss } from './Overview.style';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';

const Overview = props => {
  const {
    commercial,
    agency,
    initialValues,
    apiFormDataLength,
    finalSubmission,
    backToApiSelection,
    selectApiForm,
    backToLegalEntity,
    openWarningModal,
  } = props;

  const apiLength = `Selected API (${apiFormDataLength})`;
  return (
    <div css={OverviewCss}>
      <h1 className="h1_heading">Review and confirm your information</h1>
      <div>
        <Card
          title="Legal Entity"
          bordered={false}
          extra={
            <Button value="small" shape="round" className="edit_button" onClick={backToLegalEntity}>
              Edit
            </Button>
          }
          className="legal_Entity"
        >
          <Divider className="divider_css"></Divider>
          <Row>
            {initialValues.type === 'BROKER' ? (
              <Col sm={24} md={24} lg={6} xl={6}>
                <p>
                  Broker Portal Username
                  <br></br> <b>{initialValues?.bpUserId ? initialValues?.bpUserId : '-'}</b>
                </p>
              </Col>
            ) : (
              ''
            )}
            {initialValues.type === 'BROKER' ? (
              <Col sm={24} md={24} lg={6} xl={6}>
                <p>
                  Tax ID Number
                  <br></br> <b>{initialValues?.bpTaxId ? initialValues?.bpTaxId : '-'}</b>
                </p>
              </Col>
            ) : (
              ''
            )}
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Partner Type
                <br></br> <b>{initialValues.type}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Company Name
                <br></br> <b>{initialValues.companyName}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                First Name
                <br></br> <b>{initialValues.firstName}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Last Name
                <br></br> <b>{initialValues.lastName}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Email Id
                <br></br> <b className="emailValues">{initialValues.email}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Phone No.
                <br></br> <b>{initialValues.phoneNumber}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Address
                <br></br> <b>{initialValues.addressLine1 + ' ' + initialValues.addressLine2}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                State
                <br></br> <b>{initialValues.state}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                City
                <br></br> <b>{initialValues.city}</b>
              </p>
            </Col>
            <Col sm={24} md={24} lg={6} xl={6}>
              <p>
                Zip Code
                <br></br> <b>{initialValues.zipCode}</b>
              </p>
            </Col>
          </Row>
        </Card>
      </div>
      <Card
        title={apiLength}
        bordered={false}
        extra={
          <Button value="small" shape="round" className="edit_button" onClick={backToApiSelection}>
            Edit
          </Button>
        }
      >
        <Divider className="divider_css"></Divider>
        <Row>
          {agency.length > 0 && (
            <Fragment>
              <Col sm={24} md={24} lg={11} xl={11}>
                <h4>Agency Management API</h4>
                <Card className="agencyCard">
                  {agency &&
                    agency.map(agencyAPI => {
                      return (
                        <Col sm={24} xs={24} md={20} lg={20} xl={20} key={agencyAPI}>
                          <p>
                            <span className="tick_icon">
                              <img src={require(`./../../common/img/tick_mark.svg`).default} />
                            </span>
                            {agencyAPI}
                          </p>
                        </Col>
                      );
                    })}
                </Card>
              </Col>
              <Col sm={24} md={2} lg={2} xl={2}></Col>
            </Fragment>
          )}
          {commercial.length > 0 && (
            <Col sm={24} md={24} lg={11} xl={11}>
              <h4>Commercial Group Submission and Member Management</h4>
              <Card className="commercialCard">
                {commercial &&
                  commercial.map(commercial => {
                    return (
                      <Col sm={24} xs={24} md={20} lg={20} xl={20} key={commercial}>
                        <p>
                          <span className="tick_icon">
                            <img src={require(`./../../common/img/tick_mark.svg`).default} />
                          </span>
                          {commercial}
                        </p>
                      </Col>
                    );
                  })}
              </Card>
            </Col>
          )}
        </Row>
      </Card>
      <div className="space-btw-note"> </div>
      <Row>
        <Col sm={24} md={24} lg={16} xl={16}>
          <p className="note_text">Note: You will receive the verification email after submitting this form.</p>
        </Col>
        <Col sm={24} md={24} lg={4} xl={4}>
          <SecondaryButton className="btn-large-secondary" onClick={openWarningModal}>
            Cancel
          </SecondaryButton>
        </Col>
        <Col sm={24} md={24} lg={4} xl={4}>
          <Button
            type="app-primary"
            className="login-form-button"
            htmlType="submit"
            onClick={() => finalSubmission(selectApiForm)}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;

Overview.propTypes = {
  backToApiSelection: func,
  finalSubmission: func,
  initialValues: object,
  apiList: array,
  commercial: array,
  agency: array,
  apiFormDataLength: int,
  selectApiForm: array,
  backToLegalEntity: func,
  openWarningModal: func,
};
