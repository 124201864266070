import React, { Fragment } from 'react';
import { array, string } from 'prop-types';
import { Row, Col, Typography, Space } from 'antd';
import Slider from 'react-slick';
import { CarousalCss } from './CarousalWrapper.style';

const CarousalWrapper = props => {
  const { slides, title } = props;
  const settings = {
    useCss: true,
    useTransform: true,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Fragment>
      <div className="main_upcoming_container" css={CarousalCss}>
        <div className="div__upcoming_container">
          <Typography.Title className="h1__upcoming_api_title">{title}</Typography.Title>
          <div className="main-wrapper">
            <Slider {...settings}>
              {slides?.map((api, index) => {
                return (
                  <div className="div__slide" key={index}>
                    <Row align="middle" className="row__api bg_white" gutter={[24, 24]}>
                      <Col sm={24} xs={24} md={24} lg={10} xl={10} className="api__col">
                        <div>
                          <img
                            alt="example"
                            className="api__image"
                            src={require(`./../../common/img/${api?.name}.png`).default}
                          />
                        </div>
                      </Col>
                      <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                        <Space direction="vertical">
                          <Typography.Title className="h1__api_heading">{api?.heading}</Typography.Title>
                          <Typography.Text className="h1__api_description">{api?.description}</Typography.Text>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CarousalWrapper.propTypes = {
  slides: array,
  title: string,
};

export default CarousalWrapper;
