import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import { Button } from 'antd';
import { func, node } from 'prop-types';

export const DangerButtonCSS = css`
  border-radius: 0;
  opacity: 1;
  height: 40px;
  color: white;
  background: #e95a3c;
  box-shadow: 0px 0px 1px 0px whitesmoke;
  :hover {
    color: white;
  }
`;

export const DangerButton = props => {
  const { navigateNext, children, ...rest } = props;
  return (
    <Fragment>
      <Button css={DangerButtonCSS} type="danger" onClick={navigateNext} {...rest}>
        {children}
      </Button>
    </Fragment>
  );
};
DangerButton.propTypes = {
  navigateNext: func,
  children: node,
};
