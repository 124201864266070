import { css } from '@emotion/core';
export const appPrimaryButton = css`
  background: #286ce2 0% 0% no-repeat padding-box;
  color: white;
  border: none;
  :hover {
    background: #286ce2 0% 0% no-repeat padding-box;
  }

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background: #286ce2 !important;
  }
`;
