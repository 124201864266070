import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Input, Col, Form, Radio, Row, Select } from 'antd';
import Button from './../../components/Buttons/Button';
import { object } from 'prop-types';
import { Personas, PARTNER_WARNING_DESCRIPTION } from './../../common/constants/AppConstants';
import states from './../../common/constants/states.json';
import ModalWrapper from './../../components/ModalWrapper/ModalWrapper';
import WarningNotification from 'Client/components/WarningNotification/WarningNotification';

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const phoneNumberMaskHandler = event => {
  const value = event.target.value;
  return value.replace(phoneRegex, '$1-$2-$3');
};

const validateZipCode = (rule, value) => {
  const zipRegex = /\b^[0-9]{5}$\b/;
  if (!value) {
    return Promise.reject('Please enter your zip code');
  }
  if (!zipRegex.test(value)) {
    return Promise.reject('Zip Code must be a 5 digit Number');
  } else {
    return Promise.resolve();
  }
};

const LegalEntityForm = props => {
  const { legalInfoForm, selectApiForm } = props;
  const [personaChange, setPersonaChange] = useState(Personas.BROKER);
  const [showPartnerWarningModal, setShowPartnerWarningModal] = useState(false);

  const stateOptions = useMemo(() => {
    const options = Object.keys(states).map(x => ({ label: states[x], value: x }));
    return [{ value: '', label: '' }, ...options];
  }, []);

  useEffect(() => {
    const { type = Personas?.BROKER } = legalInfoForm.getFieldValue();
    setPersonaChange(type);
  }, []);

  const resetLegalEntityData = type => {
    legalInfoForm?.setFieldsValue({
      bpUserId: '',
      taxId: '',
      type,
    });
    setPersonaChange(type);
  };

  const modalOnCancelButtonHandler = () => {
    legalInfoForm?.setFieldsValue({
      type: personaChange,
    });
    setShowPartnerWarningModal(false);
  };

  const modalOnOkButtonHandler = () => {
    const type = legalInfoForm.getFieldValue('type');
    selectApiForm.resetFields();
    resetLegalEntityData(type);
    setShowPartnerWarningModal(false);
  };

  const onChangePersonaHandler = e => {
    const type = e?.target?.value;
    if (type === personaChange) return;
    const selectApiFormData = selectApiForm.getFieldValue('selectApi');
    if (selectApiFormData && selectApiFormData.length > 0) {
      setShowPartnerWarningModal(true);
    } else {
      resetLegalEntityData(type);
    }
  };

  return (
    <Fragment>
      <Row gutter={[12, 12]} justify="space-between" align="middle">
        <Col span={24}>
          <Form.Item
            name="type"
            label="Choose Partner Type"
            rules={[{ required: true, message: 'Please choose partner type' }]}
          >
            <Radio.Group onChange={onChangePersonaHandler}>
              <Row justify="space-between" align="middle">
                <Col sm={24} xs={24} md={8} lg={8} xl={8}>
                  <Radio value={Personas.BROKER}>Agency</Radio>
                </Col>
                <Col sm={24} xs={24} md={8} lg={8} xl={8}>
                  <Radio value={Personas.BENADMIN}>Benefit Administrator</Radio>
                </Col>
                <Col sm={24} xs={24} md={8} lg={8} xl={8}>
                  <Radio value={Personas.TECHPLATFORM}>Technology Partner</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col sm={24} xs={24} md={24} lg={24} xl={24}>
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[
              {
                required: true,
                message: 'Please enter your company name',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        {personaChange === Personas?.BROKER && (
          <Fragment>
            <Col sm={24} xs={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="bpUserId"
                label="Broker Portal Username"
                rules={[
                  {
                    message: 'Please enter your broker portal username',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={2} xs={2} md={2} lg={2} xl={2} style={{ textAlign: 'center' }}>
              <span>Or</span>
            </Col>
            <Col sm={24} xs={24} md={11} lg={11} xl={11}>
              <Form.Item
                name="bpTaxId"
                label="Tax ID Number"
                rules={[{ pattern: /^\d{9}$/, message: 'Please enter valid Tax Identification Number (SSN)' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Fragment>
        )}

        <Col sm={24} xs={24} md={11} lg={11} xl={11}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your first name',
              },
              {
                pattern: /^[A-Za-z ]+$/,
                message: 'Please enter alphabets in first name',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={11} xl={11}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your last name',
              },
              {
                pattern: /^[A-Za-z ]+$/,
                message: 'Please enter alphabets in last name',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={11} xl={11}>
          <Form.Item
            name="email"
            label="Email ID"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email ID',
              },
              {
                required: true,
                message: 'Please enter your email ID',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={11} xl={11}>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            getValueFromEvent={phoneNumberMaskHandler}
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
              {
                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                message: 'Please enter a valid phone number',
              },
            ]}
          >
            <Input autoComplete="off" id="phone-number" />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={11} xl={11}>
          <Form.Item
            name="addressLine1"
            label="Address Line1"
            rules={[
              {
                required: true,
                message: 'Please enter your address line1',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={11} xl={11}>
          <Form.Item
            name="addressLine2"
            label="Address Line2 (Optional)"
            rules={[
              {
                message: 'Please enter your address line 2',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={8} xl={8}>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: 'Please enter your state',
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp={'label'}
              notFoundContent={null}
              options={stateOptions}
              autoComplete="off"
              id="state-input"
            />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={8} xl={8}>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: 'Please enter your city',
              },
            ]}
          >
            <Input autoComplete="off" id="city" />
          </Form.Item>
        </Col>
        <Col sm={24} xs={24} md={11} lg={8} xl={8}>
          <Form.Item label="Zip Code" name="zipCode" rules={[{ required: true, validator: validateZipCode }]}>
            <Input autoComplete="off" id="zip-code" />
          </Form.Item>
        </Col>
        <Col span={24} style={{ textAlign: 'center', marginTop: 40 }}>
          <Form.Item>
            <Button type="app-primary" className="login-form-button" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <ModalWrapper visible={showPartnerWarningModal}>
        <WarningNotification
          okBtnText={'Yes, change partner type'}
          cancelBtnText={'No, continue as current partner'}
          cancelButtonHandler={modalOnCancelButtonHandler}
          okButtonHandler={modalOnOkButtonHandler}
          description={PARTNER_WARNING_DESCRIPTION}
        />
      </ModalWrapper>
    </Fragment>
  );
};
LegalEntityForm.propTypes = {
  legalInfoForm: object,
  selectApiForm: object,
};

export default LegalEntityForm;
