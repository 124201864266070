import { css } from '@emotion/core';
export const OverviewCss = css`
  .h1_heading {
    font-size: 1.2em;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
  }
  .divider_css {
    margin: 15px 0px;
  }
  .legal_Entity {
    padding: 0 5px;
  }
  .emailValues {
    word-break: break-all;
  }
  .edit_button {
    min-width: 0px;
    min-height: 0px;
    border-radius: 8px;
    color: #286ce1;
    font-size: 14px !important;
  }
  .note_text {
    font-weight: 600;
    margin-left: 20px;
    font-size: 16px;
    margin-top: 12px;
  }
  .tick_icon {
    margin-right: 10px;
  }
  .login-form-button {
    background-color: #286ce1;
    color: white;
  }

  .ant-card-head {
    font-weight: 700;
    margin-bottom: -44px;
  }
  .agencyCard {
    box-shadow: 2px 2px 4px #00000014;
  }
  .commercialCard {
    box-shadow: 2px 2px 4px #00000014;
  }
  .space-btw-note {
    margin-bottom: 35px;
  }
`;
