import React from 'react';
import { Modal } from 'antd';
import { any, bool } from 'prop-types';

const ModalWrapper = props => {
  return (
    <Modal visible={props.visible || false} onOk="" onCancel="" footer="" closable={false} centered>
      {props.children}
    </Modal>
  );
};

export default ModalWrapper;

ModalWrapper.propTypes = {
  children: any,
  visible: bool,
};
