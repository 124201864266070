import React from 'react';
import { CacheProvider } from '@emotion/core';
import createCache from '@emotion/cache';
import { node, object } from 'prop-types';

const cacheConfig = value => createCache({ ...(value || {}), key: `pat-${value?.key || 'page-name'}` });

export default function CSSCacheProvider(props) {
  return <CacheProvider value={cacheConfig(props.value)}>{props.children}</CacheProvider>;
}

CSSCacheProvider.propTypes = {
  value: object,
  children: node,
};
