import React, { Fragment, useContext, useEffect, useState } from 'react';
import BreadCrumbWrapper from './BreadCrumbWrapper';
import { Row, Col, Card, Select, Typography, Tabs } from 'antd';
import { string, array } from 'prop-types';
import { AppContext } from './../../Contexts/AppContext';
import { API_CATEGORY_CSS, MainContainer_Api_css, section__mobile_view, selectAPICSS } from './ApiService.style';
import { animateScroll, scroller } from 'react-scroll';
const { Option } = Select;
const { TabPane } = Tabs;
import { HashLink as Link } from 'react-router-hash-link';

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -220;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const routes = [
  {
    path: '',
    breadcrumbName: 'Home',
  },
  {
    path: 'api-service',
    breadcrumbName: 'API Services',
  },
];

const ApiComponent = props => {
  const { title, apiList, category } = props;
  return (
    <Fragment>
      <div className="site-card-wrapper" css={API_CATEGORY_CSS}>
        <Card
          className="card__api_list"
          title={
            <Row>
              <Col xs={0} sm={0} md={24} lg={24} xl={24}>
                <Typography.Title level={2}>{title}</Typography.Title>
              </Col>
            </Row>
          }
        >
          <Row gutter={[32, 32]}>
            {apiList?.map((api, index) => {
              return (
                <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                  <Link to={`/explore/api-service/${category}#${api?.name}`} scroll={el => scrollWidthOffset(el)}>
                    <Card
                      hoverable
                      className="card_grid_item"
                      cover={
                        <img
                          src={'/public/api/landing/fetch/' + api.name + '_apiService'}
                          className="image__grid_item"
                          style={{ width: '85%' }}
                        />
                      }
                    >
                      <Card.Meta
                        title={
                          <Typography.Title className="h1__api_heading" level={4}>
                            {api?.heading}
                          </Typography.Title>
                        }
                        description={<div dangerouslySetInnerHTML={{ __html: api?.description }} />}
                      />
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Card>
      </div>
    </Fragment>
  );
};

ApiComponent.propTypes = {
  title: string,
  apiList: array,
  category: string,
};

const ApiService = () => {
  const { wraSlotList } = useContext(AppContext);
  const [state, setState] = useState({ data: null, status: 'pending' });
  const handleChange = option => {
    switch (option) {
      case 'commercial_api':
        // commercialApiRef.current.scrollIntoView();
        scrollToCommercialApiSection();
        break;
      default:
        // commercialApiRef.current.scrollIntoView();
        scrollToAgencyApiSection();
        break;
    }
  };

  const scrollToAgencyApiSection = () => {
    animateScroll.scrollToTop({ smooth: 'easeInOutCirc', duration: 500 });
  };

  const scrollToCommercialApiSection = () => {
    scroller.scrollTo('commercial_api', { offset: -200, smooth: 'easeInOutCirc', duration: 500 });
  };

  useEffect(() => {
    const apiWidgetContent = wraSlotList?.find(data => data?.name === 'api-cards-marketing-list')?.ListAssoc || [];
    const agencyApi = apiWidgetContent?.find(data => data?.name === 'partner_agency_mgmt_apis');
    const commercialApi = apiWidgetContent?.find(data => data?.name === 'partner_comm_grp_subm_n_mbr_mgmt_apis');
    setState({
      data: { agencyApi, commercialApi },
      status: 'success',
    });
    // scrollToAgencyApiSection();
  }, []);
  return (
    <Fragment>
      <div className="site-wrapper" css={MainContainer_Api_css}>
        <section id="section__header" className="section__header">
          <Card className="card__api_section" bordered={false}>
            <Row className="row__api_header" align="middle" justify="space-between">
              <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                <BreadCrumbWrapper routes={routes} />
              </Col>
              <Col xs={0} sm={0} md={12} lg={12} xl={7}>
                <Select defaultValue="all_api" onChange={handleChange} css={selectAPICSS}>
                  <Option className="wrap__text" value="all_api">
                    All APIs
                  </Option>
                  <Option className="wrap__text" value="agency_api">
                    Agency Management API
                  </Option>
                  <Option className="wrap__text" value="commercial_api">
                    Commercial Group Submission & Member Management
                  </Option>
                </Select>
              </Col>
            </Row>
          </Card>
        </section>
        <section className="section__api">
          <Row>
            <Col sm={0} xs={0} md={24} lg={24} xl={24}>
              <div id="container">
                <div id="agency_api" className="demo">
                  <ApiComponent
                    title={state?.data?.agencyApi?.moduleTitle || ''}
                    apiList={state?.data?.agencyApi?.ListAssoc || []}
                    category="agency_api"
                  />
                </div>

                <div id="commercial_api">
                  <ApiComponent
                    title={state?.data?.commercialApi?.moduleTitle || 'Commercial Group Submission & Member Management'}
                    apiList={state?.data?.commercialApi?.ListAssoc || []}
                    category="commercial_api"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="section_api_mobile_view" css={section__mobile_view}>
          <Row>
            <Col sm={24} xs={24} md={0} lg={0} xl={0}>
              <Tabs defaultActiveKey="agency" centered>
                <TabPane tab={state?.data?.agencyApi?.moduleTitle} key="agency">
                  <ApiComponent
                    title={state?.data?.agencyApi?.moduleTitle || ''}
                    apiList={state?.data?.agencyApi?.ListAssoc || []}
                    category="agency_api"
                  />
                </TabPane>
                <TabPane tab={state?.data?.commercialApi?.moduleTitle} key="commercial">
                  <ApiComponent
                    title={state?.data?.commercialApi?.moduleTitle || 'Commercial Group Submission & Member Management'}
                    apiList={state?.data?.commercialApi?.ListAssoc || []}
                    category="commercial_api"
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </section>
      </div>
    </Fragment>
  );
};

export default ApiService;
