import { css } from '@emotion/core';
export const greetingCard = css`
  padding: 2%;
  background: inherit;

  .back-to-admin-update-partner {
    color: white;
  }
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const GetStartedCss = css`
  margin: 2% 10%;
  padding: 24px;
  background: white;

  .ant-card-head {
    border: none;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: #286ce2 0% 0% no-repeat padding-box;
    border-color: #286ce2;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #286ce2 0% 0% no-repeat padding-box;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #286ce2;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-steps-small {
    width: 50%;
    margin: auto;
  }

  .legal__heading {
    text-align: center;
  }

  .registration__card {
    margin: auto;
  }

  .ant-radio-wrapper {
    padding-right: 8%;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lato';
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-btn {
    min-width: 120px;
    min-height: 45px;
  }

  @media (min-width: 320px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    .legal__heading {
      font-size: 16px;
    }
    .registration__card {
      width: 100%;
    }
  }
  @media (min-width: 480px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    .legal__heading {
      font-size: 16px;
    }
    .registration__card {
      width: 100%;
    }
  }
  @media (min-width: 600px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    .legal__heading {
      font-size: 16px;
    }
    .registration__card {
      width: 90%;
    }
  }
  @media (min-width: 801px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    .legal__heading {
      font-size: 22px;
    }
    .registration__card {
      width: 90%;
    }
  }
  @media (min-width: 1025px) {
    margin: 2% 5%;
    padding: 24px;
    .ant-steps-small {
      width: 30%;
    }
    .legal__heading {
      font-size: 22px;
    }
    .registration__card {
      width: 100%;
    }
  }
  @media (min-width: 1281px) {
    margin: 2% 5%;
    padding: 24px;
    .ant-steps-small {
      width: 30%;
    }
    .legal__heading {
      font-size: 22px;
    }
    .registration__card {
      width: 90%;
    }
  }

  @media (min-width: 1400px) {
    margin: 2% 10%;
    padding: 24px;
    .ant-steps-small {
      width: 30%;
    }
    .legal__heading {
      font-size: 22px;
    }
    .registration__card {
      width: 90%;
    }
  }
`;

export const GetStartedLayoutCss = css`
  background: transparent linear-gradient(90deg, #286ce2 0%, #286ce2 100%) 0% 0% no-repeat padding-box;
  background-size: 100% 280px;
`;
