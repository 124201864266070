import React, { Fragment } from 'react';
import { object, func } from 'prop-types';
import { Card, Row, Col, List, Space, Typography, Divider } from 'antd';
import { css } from '@emotion/core';
import { DangerButton } from 'Client/components/Buttons/DangerButton';
import { HashLink as Link } from 'react-router-hash-link';

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -220;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export const DropDownOverlayCss = css`
  width: 85%;
  margin: auto;
  top: 29px;
  box-shadow: 1px 1px 2px 2px lightgrey;

  .btn__browse_all {
    float: right;
  }

  .divider__vertical {
    height: 100%;
    border: 1px solid lightgrey;
  }

  .divider__horizontal {
    border-top: 1px solid lightgrey;
    margin: 15px 0;
  }

  .drpTitle {
    font: normal normal bold 14px/24px Montserrat;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  @media (min-width: 1300px) {
    width: 95%;
    .img__api {
      width: 80%;
    }
    .h4__heading {
      font: normal normal 600 12px/20px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .api__description_drp {
      font: normal normal normal 12px/18px Lato;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
    }

    .api__title_section {
      padding-top: 12px;
    }
  }
  @media (min-width: 1600px) {
    width: 85%;
    .img__api {
      width: 85%;
    }
    .h4__heading {
      font: normal normal 600 14px/24px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .api__description_drp {
      font: normal normal normal 16px/24px Lato;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
    }

    .api__title_section {
      padding-top: 12px;
    }
  }
`;

const AgencyListComponent = props => {
  const { agencyApiData, closeDropDownHandler } = props;
  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__api"
        title={<span className="drpTitle">{agencyApiData?.moduleTitle}</span>}
      >
        <List
          grid={{
            column: 1,
          }}
          dataSource={agencyApiData?.ListAssoc || []}
          renderItem={api => (
            <List.Item>
              <Link
                to={`/explore/api-service/agency_api#${api?.name}`}
                scroll={el => scrollWidthOffset(el)}
                onClick={closeDropDownHandler}
              >
                <Space direction="horizontal">
                  <div>
                    <img
                      className="img__api"
                      src={require(`./../../common/img/api-services/${api?.name}_service.svg`).default}
                    />
                  </div>
                  <div className="api__title_section">
                    <Typography.Title className="h4__heading" level={4}>
                      {api?.heading}
                    </Typography.Title>
                    <Typography.Text level={4} className="api__description_drp">
                      {api?.title}
                    </Typography.Text>
                  </div>
                </Space>
              </Link>
            </List.Item>
          )}
        />
      </Card>
    </Fragment>
  );
};

AgencyListComponent.propTypes = {
  agencyApiData: object,
  closeDropDownHandler: func,
};

const CommercialListComponent = props => {
  const { commercialApiData, closeDropDownHandler } = props;

  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__api"
        title={<span className="drpTitle">{commercialApiData?.moduleTitle}</span>}
      >
        <List
          grid={{
            column: 2,
          }}
          dataSource={commercialApiData?.ListAssoc || []}
          renderItem={api => (
            <List.Item>
              <Link
                to={`/explore/api-service/commercial_api#${api?.name}`}
                onClick={closeDropDownHandler}
                scroll={el => scrollWidthOffset(el)}
              >
                <Space direction="horizontal">
                  <span>
                    <img
                      className="img__api"
                      src={require(`./../../common/img/api-services/${api?.name}_service.svg`).default}
                    />
                  </span>
                  <span>
                    <Typography.Title className="h4__heading" level={4}>
                      {api?.heading}
                    </Typography.Title>
                    <Typography.Text level={4} className="api__description_drp">
                      {api?.title}
                    </Typography.Text>
                  </span>
                </Space>
              </Link>
            </List.Item>
          )}
        />
      </Card>
    </Fragment>
  );
};

CommercialListComponent.propTypes = {
  commercialApiData: object,
  navToDetailPage: func,
  closeDropDownHandler: func,
};

const DropdownOverlayComponent = props => {
  const { agencyApiData, commercialApiData, navToOverviewPage, closeDropDownHandler } = props;
  return (
    <Fragment>
      <Card bordered={false} css={DropDownOverlayCss}>
        <Row gutter={24}>
          <Col span={6}>
            <AgencyListComponent agencyApiData={agencyApiData} closeDropDownHandler={closeDropDownHandler} />
          </Col>

          <Col span={18} style={{ borderLeft: '1px solid' }}>
            <CommercialListComponent
              commercialApiData={commercialApiData}
              closeDropDownHandler={closeDropDownHandler}
            />
          </Col>
          <Col span={24}>
            <Divider className="divider__horizontal" plain type="horizontal" />
            <DangerButton className="btn__browse_all" onClick={navToOverviewPage}>
              Browse all APIs
            </DangerButton>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

DropdownOverlayComponent.propTypes = {
  agencyApiData: object,
  commercialApiData: object,
  closeDropDownHandler: func,
  navToOverviewPage: func,
};

export default DropdownOverlayComponent;
