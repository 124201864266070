import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import { Button } from 'antd';
import { func, string } from 'prop-types';

export const TransparentCss = css`
  border-radius: 0;
  border: 0.5px solid #ffffff;
  opacity: 1;
  height: 40px;
  color: white;
  box-shadow: 0px 0px 1px 0px whitesmoke;
  :hover {
    color: white;
  }
`;

export const TransparentButton = props => {
  const { navigateNext, btnText, ...rest } = props;
  return (
    <Fragment>
      <Button css={TransparentCss} type="link" onClick={navigateNext} {...rest}>
        {btnText}
      </Button>
    </Fragment>
  );
};
TransparentButton.propTypes = {
  navigateNext: func,
  btnText: string,
};
