import React, { Fragment, useState, useEffect, useContext } from 'react';
import { array, any, func, string } from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Row, Col, Space, Typography, Card, Tabs } from 'antd';
import ErrorComponent from 'Client/components/ErrorComponent/ErrorComponent';
import ProgressBar from './../../components/ProgressBar/ProgressBar';
import CSSCacheProvider from './../../Contexts/CSSCacheProvider';
import {
  CarousalCSS,
  WelcomeSectionCSS,
  GetStartedMobileCss,
  MainContainerCss,
  APIWidgetCss,
  UserFlowCSS,
  UserFlowSectionCSS,
} from './Landing.style';
import { TransparentButton } from 'Client/components/Buttons/TransparentButton';
import { DangerButton } from 'Client/components/Buttons/DangerButton';
import ApiWidget from './ApiWidget';
import User_Flow_Diagram from '../../common/img/ProcessDiagram_Desktop.svg';
import User_Flow_Diagram_Mobile from '../../common/img/ProcessDiagram_Mobile.svg';
import { AppContext } from './../../Contexts/AppContext';
import { scroller } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import CarousalWrapper from './../../components/Carousal/CarousalWrapper';

const WelcomeSection = props => {
  const { welcomeBannerContent } = props;
  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__welcome_section"
        title={
          <div id="welcome-heading">
            <Typography.Title className="div__welcome_heading">{welcomeBannerContent?.heading}</Typography.Title>
          </div>
        }
      >
        <div id="welcome-description">
          <Typography.Paragraph className="div__welcome_description">
            <div dangerouslySetInnerHTML={{ __html: welcomeBannerContent?.copy }} />
          </Typography.Paragraph>
        </div>
      </Card>
    </Fragment>
  );
};

WelcomeSection.propTypes = {
  welcomeBannerContent: any,
};

const IntroCarousal = props => {
  const { slides, scrollToApiWidgetSection } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 9000,
    swipe: true,
    arrows: false,
  };
  const history = useHistory();

  const navNext = slide_key => {
    if (slide_key === 0) {
      // history.push('/explore#api-widget');
      scrollToApiWidgetSection();
    } else if (slide_key === 2) {
      history.push('/explore/api-service#commercial_api');
    } else {
      history.push('/explore/api-service#agency_api');
    }
  };

  return (
    <Fragment>
      <div id="carousal-container" className="container__carousal" css={CarousalCSS}>
        <Slider {...settings}>
          {slides.map((slide, index) => {
            return (
              <Fragment key={index}>
                <div id={`slide-${index}`} className={`container__slide_${index} view_height__landing`}>
                  <Row align="middle" justify="space-around" className="container__slide-row">
                    <Col
                      sm={{ order: 2, span: 24 }}
                      xs={{ order: 2, span: 24 }}
                      md={{ order: 2, span: 24 }}
                      lg={{ order: 1, span: 10 }}
                      xl={{ order: 1, span: 10 }}
                    >
                      <Space direction="vertical" size="8" className="space__container">
                        <Typography.Title className="h1__title space__item no_margin">{slide.heading}</Typography.Title>
                        <Typography.Text className="p__description space__item">{slide.description}</Typography.Text>
                        <TransparentButton
                          className="space__item"
                          btnText="Explore APIs"
                          onClick={() => navNext(index)}
                        />
                      </Space>
                    </Col>
                    <Col
                      sm={{ order: 1, span: 20 }}
                      xs={{ order: 1, span: 20 }}
                      md={{ order: 1, span: 20 }}
                      lg={{ order: 2, span: 12 }}
                      xl={{ order: 2, span: 12 }}
                    >
                      <div id={`intro_img_${index}`}>
                        <img
                          src={require(`./../../common/img/${slide?.name}.svg`).default}
                          className="image__intro_slide"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Fragment>
            );
          })}
        </Slider>
      </div>
    </Fragment>
  );
};

IntroCarousal.propTypes = {
  scrollToApiWidgetSection: func,
  slides: array,
};

const UserFlowDiagram = ({ flowTitle }) => {
  return (
    <Fragment>
      <Card
        bordered={false}
        css={UserFlowCSS}
        title={
          <div id="user_flow_heading">
            <Typography.Title className="user_flow__title">{flowTitle}</Typography.Title>
          </div>
        }
      >
        <Row gutter={24}>
          <Col xs={0} sm={0} ms={24} lg={24} xl={24}>
            <div id="user_flow_diagram">
              <img src={User_Flow_Diagram} className="user_flow__diagram" />
            </div>
          </Col>
          <Col xs={24} sm={24} ms={0} lg={0} xl={0}>
            <div id="user_flow_diagram">
              <img src={User_Flow_Diagram_Mobile} className="user_flow__diagram" />
            </div>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

UserFlowDiagram.propTypes = {
  flowTitle: string,
};

const Landing = () => {
  const [state, setState] = useState({ data: {}, status: 'pending', error: null });
  const { wraSlotList } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    getOwcsContent();
  }, []);

  const getOwcsContent = () => {
    if (wraSlotList) {
      const bannerContent =
        wraSlotList?.find(data => data?.name === 'partner_api_public_console_banner')?.ListAssoc || [];
      const welcomeBannerContent = wraSlotList?.find(data => data?.name === 'api-marketing-list')?.ListAssoc[0] || [];
      const apiWidgetContent = wraSlotList?.find(data => data?.name === 'api-cards-marketing-list')?.ListAssoc || [];
      const agencyApi = apiWidgetContent?.find(data => data?.name === 'partner_agency_mgmt_apis');
      const commercialApi = apiWidgetContent?.find(data => data?.name === 'partner_comm_grp_subm_n_mbr_mgmt_apis');
      const upcomingSection = wraSlotList?.find(data => data.name === 'partner_upcoming_apis');
      const process_diagram_section = wraSlotList?.find(
        data => data?.name === 'partner_public_site_process_flow_content',
      );

      setState({
        data: {
          bannerContent,
          welcomeBannerContent,
          agencyApi,
          commercialApi,
          upcomingSection,
          process_diagram_section,
        },
        status: 'success',
      });
    } else {
      setState({
        data: {},
        status: 'error',
      });
    }
  };

  const scrollToApiWidgetSection = () => {
    scroller.scrollTo('api-widget', { offset: -170, smooth: 'easeInOutCirc', duration: 500 });
  };

  const scrollToAgencyApi = () => {
    scroller.scrollTo('agency_api', { offset: -170, smooth: 'easeInOutCirc', duration: 500 });
  };

  const scrollToCommercialApi = () => {
    scroller.scrollTo('commercial_api', { offset: -220, smooth: 'easeInOutCirc', duration: 500 });
  };

  return (
    <Fragment>
      {state?.status === 'pending' ? (
        <ProgressBar blueColor />
      ) : state?.status === 'error' ? (
        <ErrorComponent />
      ) : (
        <Fragment>
          <section id="get-started-mobile" className="section__get-started" css={GetStartedMobileCss}>
            <DangerButton className="btn__get-started" block onClick={() => history.push('/register')}>
              Get Started
            </DangerButton>
          </section>
          <div css={MainContainerCss}>
            <section id="landing-carousal" className="section__landing_carousal">
              <IntroCarousal
                slides={state?.data?.bannerContent}
                scrollToApiWidgetSection={scrollToApiWidgetSection}
                scrollToCommercialApi={scrollToCommercialApi}
                scrollToAgencyApi={scrollToAgencyApi}
              />
            </section>
            <section id="welcome-section" className="section__welcome_section" css={WelcomeSectionCSS}>
              <WelcomeSection welcomeBannerContent={state?.data?.welcomeBannerContent} />
            </section>
            <section id="api-widget" className="section__widget_section" css={APIWidgetCss}>
              <Row justify="center" align="middle" className="col__widget_col">
                <Col sm={24} xs={24} md={24} lg={0} xl={0}>
                  <Tabs defaultActiveKey="1" centered className="tab__top-level">
                    <Tabs.TabPane
                      tab={state?.data?.agencyApi?.moduleTitle || 'Agency Management API'}
                      key="1"
                      className="tab__panel"
                    >
                      <ApiWidget apiData={state?.data?.agencyApi} category="agency_api" />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={state?.data?.commercialApi?.moduleTitle || 'Commercial Group Submission & Member Management'}
                      key="2"
                      className="tab__panel"
                    >
                      <ApiWidget apiData={state?.data?.commercialApi} category="commercial_api" />
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
                <Col xs={0} sm={0} md={0} lg={12} xl={12} className="col__widget_col">
                  <ApiWidget apiData={state?.data?.agencyApi} category="agency_api" />
                </Col>
                <Col xs={0} sm={0} md={0} lg={12} xl={12} className="col__widget_col">
                  <ApiWidget apiData={state?.data?.commercialApi} category="commercial_api" />
                </Col>
              </Row>
            </section>
            <section id="user-flow-diagram" css={UserFlowSectionCSS}>
              <UserFlowDiagram flowTitle={state?.data?.process_diagram_section?.moduleTitle} />
            </section>
            <section id="upcoming-api" className="section__upcoming_api">
              <CarousalWrapper
                slides={state?.data?.upcomingSection?.ListAssoc}
                title={state?.data?.upcomingSection?.moduleTitle || ''}
              />
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default function LazyLandingModule() {
  return (
    <CSSCacheProvider value={{ key: 'landing' }}>
      <Landing />
    </CSSCacheProvider>
  );
}
