import React, { Fragment } from 'react';
import { Result, Divider, Button } from 'antd';
import { func, string } from 'prop-types';
import { css } from '@emotion/core';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const warningCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
    font-family: Montserrat;
    font-weight: 500;
  }

  &.ant-result-info .ant-result-icon > .anticon {
    color: #286ce2;
  }

  .ant-result-icon > .anticon {
    font-size: 55px;
  }
  &.ant-result {
    padding: 37px 37px;
  }

  .ant-divider {
    border: none;
  }
`;

const WarningNotification = props => {
  const { okButtonHandler, cancelButtonHandler, description, okBtnText, cancelBtnText } = props;

  return (
    <Result
      css={warningCss}
      title={description}
      icon={<ExclamationCircleOutlined />}
      extra={
        <Fragment>
          <Button
            type="default"
            size="large"
            className="btn-large-secondary"
            block
            onClick={cancelButtonHandler}
            id="reset-cancel-btn"
          >
            {cancelBtnText}
          </Button>
          <Divider style={{ margin: '10px' }} />
          <Button
            type="primary"
            size="large"
            className="btn-large-primary"
            block
            onClick={okButtonHandler}
            id="reset-ok-btn"
          >
            {okBtnText}
          </Button>
        </Fragment>
      }
    />
  );
};

export default WarningNotification;

WarningNotification.propTypes = {
  okButtonHandler: func,
  cancelButtonHandler: func,
  description: string,
  okBtnText: string,
  cancelBtnText: string,
  activeTabKey: string,
};
