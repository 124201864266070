import React, { Fragment } from 'react';
import { Breadcrumb, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { array } from 'prop-types';

function itemRender(route, _params, routes) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <Typography.Title level={4} className="span__item span__nav_current_page">
      {route.breadcrumbName}
    </Typography.Title>
  ) : (
    <Link className="span__item span__nav__link" to={route?.path} replace>
      {route.breadcrumbName}
    </Link>
  );
}

const BreadCrumbWrapper = props => {
  const { routes } = props;
  return (
    <Fragment>
      <Breadcrumb itemRender={itemRender} routes={routes} separator=">" />
    </Fragment>
  );
};

BreadCrumbWrapper.propTypes = {
  routes: array,
};

export default BreadCrumbWrapper;
