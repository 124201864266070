import { css } from '@emotion/core';
export const CarousalCSS = css`
  .no_margin {
    margin: 0;
  }
  .space__container .ant-space-item {
    padding: 15px 5px;
  }
  .container__slide_0 {
    background: transparent linear-gradient(89deg, #0068a6 0%, #0078c1 100%) 0% 0% no-repeat padding-box;
  }
  .container__slide_1 {
    background: transparent linear-gradient(89deg, #0068a6 0%, #0078c1 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .container__slide_2 {
    background: transparent linear-gradient(89deg, #0068a6 0%, #0078c1 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .slick-dots li button:before {
    color: white;
  }

  @media (min-width: 320px) {
    .view_height__landing {
      height: 85vh;
    }
    .image__intro_slide {
      width: 70%;
      margin: auto;
    }
    .h1__title {
      color: white;
      font-size: large;
    }
    .p__description {
      color: white;
      font-size: small;
    }
    .container__slide-row {
      position: relative;
      left: 0;
      padding: 24px;
    }
    .slick-dots {
      top: 0;
      bottom: auto;
      right: 0;
      width: auto;
      text-align: right;
    }
  }
  @media (min-width: 480px) {
    .view_height__landing {
      height: 85vh;
    }
    .image__intro_slide {
      width: 70%;
      margin: auto;
    }
    .h1__title {
      color: white;
      font-size: 1.5em;
    }
    .p__description {
      color: white;
      font-size: 1rem;
    }
    .container__slide-row {
      position: relative;
      left: 0;
      padding: 24px;
    }
    .slick-dots {
      top: 0;
      bottom: auto;
      right: 0;
      width: auto;
      text-align: right;
    }
  }
  @media (min-width: 600px) {
    .view_height__landing {
      height: 85vh;
    }
    .image__intro_slide {
      width: 80%;
      margin: auto;
    }
    .h1__title {
      color: white;
      font-size: 3em;
      line-height: normal;
    }
    .p__description {
      color: white;
      font-size: 1.3rem;
      line-height: normal;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: 0;
      bottom: auto;
      right: 0;
      width: auto;
      text-align: right;
    }
  }
  @media (min-width: 801px) {
    .view_height__landing {
      height: 430px;
    }
    .image__intro_slide {
      width: 80%;
      margin: auto;
    }
    .h1__title {
      text-align: left;
      font: normal normal 520 38px/50px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .p__description {
      font: normal normal normal 16px/24px Lato;
      letter-spacing: 0px;
      color: #e9e9e9;
      opacity: 1;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: auto;
      bottom: 5%;
      left: 8%;
      text-align: left;
    }
  }
  @media (min-width: 1025px) {
    .view_height__landing {
      height: 470px;
    }
    .image__intro_slide {
      width: 80%;
      margin: auto;
    }
    .h1__title {
      text-align: left;
      font: normal normal 520 38px/50px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .p__description {
      font: normal normal normal 16px/24px Lato;
      letter-spacing: 0px;
      color: #e9e9e9;
      opacity: 1;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: auto;
      bottom: 5%;
      left: 8%;
      text-align: left;
    }
  }
  @media (min-width: 1400px) {
    .view_height__landing {
      height: auto;
    }
    .image__intro_slide {
      width: 85%;
      margin: auto;
    }
    .h1__title {
      text-align: left;
      font: normal normal 520 38px/50px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .p__description {
      font: normal normal normal 24px/34px Lato;
      letter-spacing: 0px;
      color: #e9e9e9;
      opacity: 1;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: auto;
      bottom: 5%;
      left: 8%;
      text-align: left;
    }
  }
`;

export const WelcomeSectionCSS = css`
  position: relative;
  .ant-card-head {
    border: none;
  }

  @media (min-width: 320px) {
    &.section__welcome_section {
      padding: 0px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 22px;
    }

    .div__welcome_description {
      font-size: 12px;
      font-weight: 500;
    }
  }
  @media (min-width: 480px) {
    &.section__welcome_section {
      padding: 0px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 22px;
    }

    .div__welcome_description {
      font-size: 12px;
      font-weight: 500;
    }
  }
  @media (min-width: 600px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 22px;
    }

    .div__welcome_description {
      font-size: 12px;
    }
  }
  @media (min-width: 801px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      width: 70%;
      text-align: center;
      margin: 0 12%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 30px;
    }

    .div__welcome_description {
      font-size: 18px;
    }
  }

  @media (min-width: 1281px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 350px;
    }
    .card__welcome_section {
      width: 70%;
      text-align: center;
      margin: 0 12%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
    }
    .div__welcome_heading {
      font: normal normal 600 30px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .div__welcome_description {
      font: normal normal normal 16px/18px Lato;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
  }

  @media (min-width: 1600px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      width: 70%;
      text-align: center;
      margin: 0 12%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
    }
    .div__welcome_heading {
      font: normal normal 600 36px/46px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .div__welcome_description {
      font: normal normal normal 18px/22px Lato;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
  }
`;

export const GetStartedMobileCss = css`
  @media (min-width: 320px) {
    &.section__get-started {
      display: block;
      position: fixed;
      width: 100%;
      height: 70px;
      background: white;
      z-index: 9;
    }

    .btn__get-started {
      width: 81%;
      position: relative;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 480px) {
    &.section__get-started {
      display: block;
      position: fixed;
      width: 100%;
      height: 70px;
      background: white;
      z-index: 9;
    }
    .btn__get-started {
      width: 81%;
      position: relative;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 600px) {
    &.section__get-started {
      display: block;
      position: fixed;
      width: 100%;
      height: 70px;
      background: white;
      z-index: 9;
    }
    .btn__get-started {
      width: 81%;
      position: relative;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 801px) {
    &.section__get-started {
      display: none;
    }
  }
  @media (min-width: 1025px) {
    &.section__get-started {
      display: none;
    }
  }
  @media (min-width: 1281px) {
    &.section__get-started {
      display: none;
    }
  }
`;
export const MainContainerCss = css`
  @media (min-width: 320px) {
    margin-top: 20%;
  }
  @media (min-width: 480px) {
    margin-top: 19%;
  }
  @media (min-width: 600px) {
    margin-top: 10%;
  }
  @media (min-width: 801px) {
    margin-top: 0%;
  }
  @media (min-width: 1025px) {
    margin-top: 0%;
  }
  @media (min-width: 1281px) {
    margin-top: 0%;
  }
`;

export const APIWidgetCss = css`
  .card__api_widget {
    background: transparent;
  }
  .card__api_widget .ant-card-head {
    border: none;
    text-align: center;
  }

  .typography__api_title {
    font-size: 1em;
  }

  .card__api_list {
    text-align: center;
    box-shadow: 4px 4px 8px #0000000f;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    opacity: 1;
    position: relative;
    margin: auto;
  }

  .card__api_list:hover {
    box-shadow: 3px 3px 6px #cccccc;
    transform: scale(1.1, 1.1);
  }

  .card__api_list .ant-card-body {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .image__card_shot_list {
    width: 100%;
  }

  .col__widget_col {
    padding: 0 4%;
    background: transparent linear-gradient(90deg, #f7f7f7 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    box-sizing: border-box;
  }

  .browse_all {
    box-shadow: 3px 3px 6px #0000000f;
    border: 1px solid #286ce2;
  }

  .link__heading {
    color: #286ce2;
  }

  .typography__api_heading {
    height: 70px;
    font-size: 2em;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .ant-tabs-tab {
    margin: 0 32px;
  }

  .ant-tabs-nav-wrap {
    white-space: normal !important;
  }
  @media (min-width: 320px) {
    .section__api_widget {
      height: 55vh;
    }
    .card__api_list {
      width: 140px;
      height: 140px;
    }
    .typography__api_heading {
      font-size: 22px;
    }
    .image__card_shot_list {
      width: 55%;
    }
    .browse-all-logo {
      width: 35%;
    }
  }
  @media (min-width: 480px) {
    .section__api_widget {
      height: 65vh;
    }
    .card__api_list {
      width: 140px;
      height: 140px;
    }
    .typography__api_heading {
      font-size: 22px;
    }
    .image__card_shot_list {
      width: 55%;
    }
    .browse-all-logo {
      width: 35%;
    }
  }
  @media (min-width: 600px) {
    .section__api_widget {
      height: 65vh;
    }
    .card__api_list {
      width: 220px;
      height: 220px;
    }
    .typography__api_heading {
      font-size: 22px;
    }
    .image__card_shot_list {
      width: 55%;
    }
    .browse-all-logo {
      width: 55%;
    }
  }
  @media (min-width: 801px) {
    .section__api_widget {
      height: 550px;
    }
    .card__api_list {
      width: 220px;
      height: 220px;
    }
    .typography__api_heading {
      font-size: 25px;
    }
    .image__card_shot_list {
      width: 75%;
    }
    .browse-all-logo {
      width: 55%;
    }
  }
  @media (min-width: 1025px) {
    .section__api_widget {
      height: 550px;
    }
    .card__api_list {
      width: 180px;
      height: 180px;
    }
    .typography__api_heading {
      font-size: 25px;
    }
    .image__card_shot_list {
      width: 70%;
    }
    .browse-all-logo {
      width: 50%;
    }
  }
  @media (min-width: 1400px) {
    .section__api_widget {
      height: 760px;
    }
    .card__api_list {
      width: 240px;
      height: 240px;
    }
    .typography__api_heading {
      font-size: 30px;
    }
    .image__card_shot_list {
      width: 100%;
    }
    .browse-all-logo {
      width: 50%;
    }
  }
`;

export const UserFlowCSS = css`
  .user_flow__title {
    text-align: center;
    font: normal normal bold 32px/39px Montserrat;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  .ant-card-head {
    border-bottom: none;
  }
  img {
    display: block;
    margin: auto;
    width: 80%;
  }
  /* @media (max-width: 768px) {
    .user_flow__title {
      font-size: 1.3em;
    }
  } */

  @media (min-width: 320px) {
    .user_flow__title {
      font: normal normal bold 18px/28px Montserrat;
    }
  }
  @media (min-width: 480px) {
    .user_flow__title {
      font: normal normal bold 18px/28px Montserrat;
    }
  }
  @media (min-width: 600px) {
    .user_flow__title {
      font: normal normal bold 18px/28px Montserrat;
    }
  }
  @media (min-width: 801px) {
    .user_flow__title {
      font: normal normal bold 28px/34px Montserrat;
    }
  }
  @media (min-width: 1300px) {
    .user_flow__title {
      font: normal normal bold 28px/34px Montserrat;
    }
  }
  @media (min-width: 1600px) {
    .user_flow__title {
      font: normal normal bold 32px/39px Montserrat;
    }
  }
`;

export const UserFlowSectionCSS = css`
  padding: 24px;
  background: #ffffff;
`;
