import React, { createContext, Fragment, useEffect, useState } from 'react';
import { getPublicSiteApi } from './../services/landingServices';
import ProgressBar from './../components/ProgressBar/ProgressBar';
import ErrorComponent from 'Client/components/ErrorComponent/ErrorComponent';
import { node } from 'prop-types';
import { getClientConfig } from './../services/clientConfig';

export const AppContext = createContext();

export const AppContentProvider = ({ children }) => {
  const [state, setState] = useState({ data: null, status: 'pending' });

  useEffect(() => {
    Promise.all([getPublicSiteApi(), getClientConfig()])
      .then(responses => {
        const { wraSlotList } = responses?.[0]?.data?.pageSlotList[0];
        const clientConfig = responses?.[1]?.data;
        setState({ data: { wraSlotList, clientConfig }, status: 'completed' });
      })
      .catch(() => {
        setState({ data: null, status: 'error' });
      });
  }, []);

  return (
    <Fragment>
      {state?.status === 'pending' ? (
        <ProgressBar blueColor />
      ) : state?.status === 'error' ? (
        <ErrorComponent />
      ) : (
        <AppContext.Provider value={state.data}>{children}</AppContext.Provider>
      )}
    </Fragment>
  );
};

AppContentProvider.propTypes = {
  children: node.isRequired,
};
