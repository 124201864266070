import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Card, Button, Divider, Row, Col } from 'antd';
import { css } from '@emotion/core';
import ExpiredImg from './../../common/img/LinkExpired.svg';
import { resendEmail } from './../../services/registration.service';
import { useHistory } from 'react-router-dom';
import MailImg from './../../common/img/email-confirmation.svg';

export const greetingCardHeader = css`
  padding: 3em 3.8em 2em;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const cssContent = css`
  background: transparent linear-gradient(90deg, #58abdf 0%, #286ce2 100%) 0% 0% no-repeat padding-box;
  background-size: 100% 200px;
  padding: 0 100px;
  margin-bottom: 4em;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const expiredCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
  }

  span.ant-typography {
    font-family: Montserrat;
    font-weight: 500;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: -0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.23;
  }

  #resend-email-btn {
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }
`;

const Expired = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const tokenId = window.location.pathname.split('expired/')[1];
  const [resendSuccess, setResendSuccess] = useState(false);
  const [emailId, setEmailId] = useState('');
  const resendEmailCode = () => {
    resendEmail(tokenId)
      .then(res => {
        setEmailId(res.data?.results?.partnerEmail);
        if (res?.status === 200) {
          setResendSuccess(true);
        }
      })
      .catch(err => {
        history.push('/register/error');
        setResendSuccess(false);
        console.error('error', err);
      });
  };

  let str = emailId;
  str = str.split('');
  let finalArr = [];
  let len = str.indexOf('@');
  str.forEach((item, pos) => {
    pos >= 1 && pos <= len - 2 ? finalArr.push('*') : finalArr.push(str[pos]);
  });
  return (
    <Fragment>
      <div css={cssContent}>
        <Card css={greetingCardHeader} bordered={false}></Card>
        <Card css={expiredCss}>
          {!resendSuccess ? (
            <div className="ant-result ant-result-info">
              <div className="ant-result-icon">
                <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                  <img src={ExpiredImg}></img>
                </span>
              </div>
              <div className="ant-result-title">
                <Fragment>
                  <Typography.Title>Your verification link has expired.</Typography.Title>
                </Fragment>
              </div>
              <div className="ant-result-extra">
                <Fragment>
                  <Row gutter={24} justify="center">
                    <Col md={24}>
                      <Typography.Text>
                        Resend the verification link to verify your email address with us and complete the registration.
                      </Typography.Text>
                      <Divider style={{ margin: '18px' }} />
                    </Col>
                    <Col md={6}>
                      <Button
                        type="primary"
                        size="large"
                        className="btn-large-primary"
                        block
                        onClick={() => resendEmailCode(tokenId)}
                        id="resend-email-btn"
                      >
                        Resend email
                      </Button>
                      <Divider style={{ margin: '2px' }} />
                    </Col>
                  </Row>
                </Fragment>
              </div>
            </div>
          ) : (
            <div className="ant-result ant-result-info">
              <div className="ant-result-icon">
                <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                  <img src={MailImg}></img>
                </span>
              </div>
              <div className="ant-result-title">
                <Fragment>
                  <Typography.Title>Resent the verification link</Typography.Title>
                </Fragment>
              </div>
              <div className="ant-result-extra">
                <Fragment>
                  <Row gutter={24} justify="center">
                    <Col md={24}>
                      <Typography.Text>
                        Please check your email {emailId ? finalArr.join('') : ''} for the verification message and
                        click `Verify email` link to complete the registration.
                      </Typography.Text>
                      <Divider style={{ margin: '10px' }} />
                      <Typography.Text>Subject line: Partner API Email Verification</Typography.Text>
                      <Divider style={{ margin: '20px' }} />
                      <Typography.Text strong>Please note:</Typography.Text>
                      <Typography.Text> The verification link is valid only for 24hrs.</Typography.Text>
                      <Divider style={{ margin: '2px' }} />
                    </Col>
                  </Row>
                </Fragment>
              </div>
            </div>
          )}
        </Card>
      </div>
    </Fragment>
  );
};

export default Expired;
