import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Row, Col, Layout, Menu, Space, Button, Dropdown, Typography, Divider } from 'antd';
import { AppContext } from './../../Contexts/AppContext';
import { LockOutlined, DownOutlined } from '@ant-design/icons';
import { DangerButton } from 'Client/components/Buttons/DangerButton';
import { useHistory, useLocation } from 'react-router-dom';
import DropdownOverlayComponent from './DropDownOverlayComponent';
import MobileDrawer from './MobileDrawer';
import { MenuFoldOutlined } from '@ant-design/icons';
import { HeaderCss } from './Header.style';

const HeaderComponent = () => {
  const history = useHistory();
  const [showHomeSection, setShowHomeSection] = useState(true);
  const [state, setState] = useState({
    data: { agencyManagementApi: null, commercialManagementApi: null },
    error: null,
    status: 'pending',
  });
  const [activeMenu, setActiveMenu] = useState('home');
  const [drpVisible, setDrpVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { wraSlotList } = useContext(AppContext);
  let location = useLocation();

  useEffect(() => {
    getActiveMenuLabelName();
    populateLandingPageContents();
    const isRegistrationPathFound = location?.pathname?.split('/')?.includes('register');
    isRegistrationPathFound ? setShowHomeSection(false) : setShowHomeSection(true);
  }, [location]);

  const getActiveMenuLabelName = () => {
    const activeMenu = history?.location?.pathname?.split('/');
    if (activeMenu?.includes('api-service')) {
      setActiveMenu('api-service');
    } else {
      setActiveMenu('home');
    }
  };

  const populateLandingPageContents = () => {
    const apiAssocList = wraSlotList?.find(data => data?.name === 'api-cards-marketing-list')?.ListAssoc || [];
    const agencyManagementApi = apiAssocList?.find(data => data?.name === 'partner_agency_mgmt_apis');
    const commercialManagementApi = apiAssocList?.find(data => data?.name === 'partner_comm_grp_subm_n_mbr_mgmt_apis');
    setState({
      data: { agencyManagementApi, commercialManagementApi },
      status: 'success',
    });
  };

  const handleDrpVisibleHandler = () => {
    setDrpVisible(!drpVisible);
  };

  const closeDropDownHandler = () => {
    setDrpVisible(false);
  };

  const navToOverviewPage = () => {
    closeDropDownHandler();
    setActiveMenu('api-service');
    history.push('/explore/api-service');
  };

  const closeDrawer = () => setDrawerVisible(false);

  const openDrawer = () => setDrawerVisible(!drawerVisible);

  const imageURL = '/public/api/landing/fetch/anthem-branding-expanded-image.png';
  return (
    <Fragment>
      <Layout.Header css={HeaderCss} className="header__container">
        {showHomeSection ? (
          <Row>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col span={6}>
                  <img src={imageURL} className="img-logo" onClick={() => history.push('/explore')} />
                </Col>
                <Col xs={0} sm={0} md={0} lg={6} xl={5} className="col__menu_section">
                  <Menu selectedKeys={[activeMenu]} mode="horizontal">
                    <Menu.Item
                      className="menu__item"
                      key="home"
                      onClick={() => {
                        setActiveMenu('home');
                        history.push('/');
                      }}
                    >
                      Home
                    </Menu.Item>
                    <Menu.Item key="api-service" className="menu__item">
                      <Dropdown
                        overlay={
                          <DropdownOverlayComponent
                            agencyApiData={state?.data?.agencyManagementApi}
                            commercialApiData={state?.data?.commercialManagementApi}
                            navToOverviewPage={navToOverviewPage}
                            closeDropDownHandler={closeDropDownHandler}
                          />
                        }
                        onVisibleChange={handleDrpVisibleHandler}
                        visible={drpVisible}
                        placement="bottomCenter"
                        trigger={['click']}
                        overlayClassName="dropdown__overlay"
                        overlayStyle={{ position: 'fixed', top: '57px' }}
                      >
                        <span>
                          API services <DownOutlined />
                        </span>
                      </Dropdown>
                    </Menu.Item>
                    {/* <Menu.Item key="partners" className="menu__item">
                      Partners
                    </Menu.Item> */}
                  </Menu>
                </Col>
                <Col xs={0} sm={0} md={0} lg={5} xl={5} className="align-center">
                  <Space>
                    <Button
                      icon={<LockOutlined />}
                      className="header__login_btn"
                      onClick={() => window.location.assign('/login')}
                    >
                      Login
                    </Button>

                    <DangerButton className="header__register-btn" onClick={() => history.push('/register')}>
                      Get started
                    </DangerButton>
                  </Space>
                </Col>
                <Col xs={6} sm={6} md={4} lg={0} xl={0} className="align-right">
                  <Space>
                    <a onClick={() => window.location.assign('/login')}>
                      <img src={require('./../../common/img/lock-icon.svg').default} />
                    </a>
                    <Button className="hamburger__menu" onClick={openDrawer} type="link" icon={<MenuFoldOutlined />} />
                  </Space>
                  <MobileDrawer
                    agencyApiData={state?.data?.agencyManagementApi}
                    commercialApiData={state?.data?.commercialManagementApi}
                    closeDrawer={closeDrawer}
                    visible={drawerVisible}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row justify="space-between">
            <Col span={6}>
              <img src={imageURL} className="img-logo" onClick={() => history.push('/explore')} />
            </Col>
            <Col xs={0} sm={0} md={10} lg={10} xl={6} className="align-center">
              <Space split={<Divider type="vertical" />}>
                <Typography.Text className="already__registered" style={{ fontWeight: 500 }}>
                  Already Registered?
                </Typography.Text>

                <Button
                  icon={<LockOutlined />}
                  className="header__login_btn"
                  onClick={() => window.location.assign('/login')}
                >
                  Login
                </Button>
              </Space>
            </Col>
            <Col xs={6} sm={6} md={0} lg={0} xl={0} className="align-right">
              <Space>
                <a onClick={() => window.location.assign('/login')}>
                  <img src={require('./../../common/img/lock-icon.svg').default} />
                </a>
              </Space>
            </Col>
          </Row>
        )}
      </Layout.Header>
    </Fragment>
  );
};

export default HeaderComponent;
