import React from 'react';
import { Col, Row, Card, Typography, Form, Checkbox, Button, Tooltip } from 'antd';
import { API_DEVELOPMENT_STATUS } from './../../common/constants/AppConstants';
import { node, string, any, func, bool } from 'prop-types';
import { ApiDetailCSS } from '../Landing/ApiDetailsComponent.style';

const ApiComponent = props => {
  const {
    apiId,
    apiTitle,
    apiDescription,
    isSelectedFormFieldPath,
    apiStatus,
    changeSelectAllFlag,
    notificationSupported,
    tooltipText,
  } = props;

  return (
    <Card className="api-info">
      <Row gutter={[24, 24]} justify="space-around" align="middle" className="app-row">
        <Col sm={24} xs={24} md={6} lg={3}>
          <div className="api-img" css={ApiDetailCSS}>
            <img
              //src={require(`./../../common/img/api/${apiId?.toUpperCase()}.svg`).default}
              src={'/public/api/landing/fetch/' + apiId + '_apiService'}
              className="image__grid_item"
            />
          </div>
        </Col>
        <Col sm={24} xs={24} md={18} lg={14}>
          <Typography.Title level={3} id={`${apiTitle}-api-title`} className="h2__title">
            {apiTitle}
          </Typography.Title>
          <Typography.Text level={4} className="h6__description">
            <span dangerouslySetInnerHTML={{ __html: apiDescription }}></span>
          </Typography.Text>
        </Col>
        <Col sm={24} xs={24} md={12} lg={4} className="subscription-col">
          {notificationSupported && (
            <Form.Item name={isSelectedFormFieldPath}>
              <Tooltip className="notification-tooltip" title={tooltipText} color={'white'} key={'white'}>
                <Button type="primary" className="notification-btn">
                  <img className="notification-img" src={require('./../../common/img/NotificationBell.svg').default} />
                  Get Notifications
                </Button>
              </Tooltip>
            </Form.Item>
          )}
        </Col>
        {apiStatus !== API_DEVELOPMENT_STATUS && (
          <Col sm={24} xs={24} md={12} lg={3} className="subscription-col">
            <Form.Item name={isSelectedFormFieldPath} valuePropName="checked">
              <Checkbox className="tag-btn" onChange={changeSelectAllFlag} id={`${apiId}-select-btn`}>
                Select
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Card>
  );
};

ApiComponent.propTypes = {
  apiId: node,
  apiTitle: string,
  apiDescription: string,
  isSelectedFormFieldPath: any,
  apiStatus: string,
  changeSelectAllFlag: func,
  notificationSupported: bool,
  tooltipText: string,
};

export default ApiComponent;
