export const APP_NAME = 'SalesHive';
export const LOGIN = 'Login';
export const REGISTER = 'Register';
export const EXPLORE_API = 'Explore APIs';

export const Personas = {
  BENADMIN: 'BENADMIN',
  TECHPLATFORM: 'TECHPLATFORM',
  BROKER: 'BROKER',
};

export const API = {
  APP_DETAILS_API: 'APP_DETAILS_API',
  PULL_STATUS_API: 'PULL_STATUS_API',
  OPTY_MGMT_API: 'OPTY_MGMT_API',
  MBR_ENROLL_API: 'MBR_ENROLL_API',
  DOC_UPLOAD_API: 'DOC_UPLOAD_API',
  COMM_API: 'COMM_API',
  BOB_API: 'BOB_API',
  APP_SUBMISSION_API: 'APP_SUBMISSION_API',
  GRP_STRUCT_API: 'GRP_STRUCT_API',
  APP_STATUS_NOTIF: 'APP_STATUS_NOTIF',
  MBR_MNTNC_API: 'MBR_MNTNC_API',
  BENEFIT_DETAILS_API: 'BENEFIT_DETAILS_API',
  BROKER_NEWS_FEED_API: 'BROKER_NEWS_FEED_API',
};

export const API_DEVELOPMENT_STATUS = 'Development';
export const CANCEL_MODAL_DESCRIPTION = 'Are you sure you want to cancel the registration?';
export const PARTNER_WARNING_DESCRIPTION = `Changing the partner type may reset the information filled or APIs selected, would you still want to
change the partner type?`;
export const PARTNER_REGISTER_CONFIRMATION = `Thank you for showing interest in our APIs. Our team will review your details and get back to you soon.`;
export const PARTNER_REGISTER_DESCRIPTION = `Please wait for registration confirmation email from our business team`;

export const INVALID_USER_DETAILS_404 = `The Agency UserID or TIN doesn't match our records. Please enter correct Broker Portal Username and/or Tax Identification Number (TIN).`;
export const PARTNER_EXISTS_409 = `A partner already exists with the details provided. Please re-verify or click login if you already have Secured Console credentials.`;
export const BROKER_USER_TIN_CONFLICT_409 = `The agency userid and TIN doesn't match as per our records. Please enter correct Broker Portal username and/or Tax Identification Number (TIN)`;
export const SOMETHING_WENT_WRONG =
  'Something went wrong, please try again later or reach out to our Technical Support Center';
