import React from 'react';
import { render } from 'react-dom';
import { AppProviders } from './Contexts/AppProviders';
import App from './App';
import './index.css';

render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
);
