import React, { Fragment, useState, useEffect } from 'react';
import { Card } from 'antd';
import { css } from '@emotion/core';
import { emailVerificationCode } from './../../services/registration.service';
import { useHistory } from 'react-router-dom';

export const greetingCardHeader = css`
  padding: 3em 3.8em 2em;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const cssContent = css`
  background: transparent linear-gradient(90deg, #58abdf 0%, #286ce2 100%) 0% 0% no-repeat padding-box;
  background-size: 100% 200px;
  padding: 0 100px;
  margin-bottom: 4em;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const EmailConfirmation = () => {
  const history = useHistory();
  const [emailVerificationStatus, setEmailVerificationStatus] = useState({ status: 'pending', data: {}, error: null });
  useEffect(() => {
    window.scrollTo(0, 0);
    emailVerificationCodes();
  }, []);

  const emailVerificationCodes = () => {
    const pathName = window.location.pathname.split('confirm/')[1];
    const tokenId = pathName.split('/')[0];
    console.log(tokenId);
    emailVerificationCode(tokenId)
      .then(res => {
        setEmailVerificationStatus({ data: res, status: 'success', error: null });
        if (res?.status === 200) {
          history.push('/register/thankyou');
        }
      })
      .catch(err => {
        setEmailVerificationStatus({ data: err?.response?.status, status: 'error', error: err });
        if (err?.response?.status === 409) {
          history.push('/register/verified');
        } else if (err?.response?.status === 403) {
          history.push('/register/expired/' + tokenId);
        } else {
          history.push('/register/error');
        }
      });
  };
  return (
    <Fragment>
      <div css={cssContent}>
        <Card css={greetingCardHeader} bordered={false}></Card>
      </div>
    </Fragment>
  );
};

export default EmailConfirmation;
