import React from 'react';
import { node } from 'prop-types';
import { Layout, BackTop } from 'antd';
import Header from './../Header/Header';
import FooterComponent from './../Footer/Footer';

import { AppContentCss, AppLayoutCss } from './AppLayout.style';

export default function AppLayout(props) {
  return (
    <Layout id="internal-app-layout" css={AppLayoutCss}>
      <Header id="internal-app-header" />
      <Layout.Content id="internal-app-content" css={AppContentCss}>
        {props.children}
      </Layout.Content>
      <FooterComponent />
      <BackTop id="internal-app-backToTop" />
    </Layout>
  );
}

AppLayout.propTypes = {
  children: node,
};
