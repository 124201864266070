import React, { useEffect, Fragment, useContext } from 'react';
import { array, string, object, func } from 'prop-types';
import { Card, Typography, Form, Col, Row, Checkbox, notification } from 'antd';
import { API_DEVELOPMENT_STATUS } from './../../common/constants/AppConstants';
import ApiComponent from './ApiComponent';
import { ApiSelectionCss } from './ApiSelection.style';
import Button from 'Client/components/Buttons/Button';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import LicenseAgreement from './LicenseAggrement';
import { AppContext } from './../../Contexts/AppContext';

const ApiSelection = props => {
  const { selectApiForm, partnerType, apiList, backToLegalEntity, nextToOverview, openWarningModal } = props;
  const { clientConfig } = useContext(AppContext);

  useEffect(() => {
    const selectApiFormVal = selectApiForm?.getFieldValue('selectApi');
    const selectedPersona = selectApiForm?.getFieldValue('selectedPersona');
    if (!selectApiFormVal || !selectApiFormVal?.length > 0 || isPersonaChange(partnerType, selectedPersona)) {
      constructSelectApiForm(partnerType);
    }
  });

  const constructSelectApiForm = selectedPersona => {
    selectApiForm.setFieldsValue({
      selectApi: populateSelectApiList(selectedPersona),
      selectedPersona,
      selectAll: false,
    });
  };

  const populateSelectApiList = selectedPersona => {
    const apiData = apiList?.filter(api => api?.persona?.includes(selectedPersona));
    return apiData?.map(api => {
      return {
        selectedPersona,
        apiId: api?.apiId,
        description: api?.apiDescription,
        apiName: api?.apiName,
        apiStatus: api?.apiStatus,
        notificationSupported: api?.notificationSupported,
        tooltipText: api.toolTip,
        isSelected: false,
      };
    });
  };

  const isPersonaChange = (prev, curr) => {
    return prev !== curr;
  };

  const changeSelectAllFlag = () => {
    const formData = selectApiForm.getFieldValue('selectApi');
    const exceptDevelopmentStatusAPI = formData?.filter(data => data?.apiStatus !== API_DEVELOPMENT_STATUS);
    const disableSelectAllFlag = exceptDevelopmentStatusAPI?.some(data => !data?.isSelected);
    selectApiForm.setFieldsValue({ selectAll: !disableSelectAllFlag });
  };

  const selectAllApiHandler = () => {
    const formData = selectApiForm.getFieldValue('selectApi');
    const selectAllFlag = selectApiForm.getFieldValue('selectAll') || false;
    const updateFormData = formData?.map(api => {
      if (api?.apiStatus !== API_DEVELOPMENT_STATUS) {
        api.isSelected = selectAllFlag;
      }
      return api;
    });
    // const isSelectAll = updateFormData.isSelected === true ? true : false;
    selectApiForm.setFieldsValue({ selectApi: updateFormData });
  };

  const onFinishHandler = values => {
    const data = values?.selectApi || [];
    if (validateForm(data)) {
      const selectedApi = data?.filter(api => api?.isSelected);
      nextToOverview(selectedApi);
    } else {
      notification.error({
        message: 'Select API',
        description: 'Please select atleast 1 API before submitting the request form',
        style: {
          width: '60vw',
        },
      });
    }
  };

  const validateForm = data => {
    return data?.some(api => api?.isSelected);
  };

  return (
    <Fragment>
      <Form
        form={selectApiForm}
        layout="vertical"
        name="selectApiData"
        onFinish={values => onFinishHandler(values)}
        hideRequiredMark={true}
        css={ApiSelectionCss}
        scrollToFirstError
      >
        <Fragment>
          <Card bordered={false}>
            <Row gutter={[12, 12]} className="api-title-head" align="middle" justify="center">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Typography.Title className="h1__title" level={1}>
                  Anthem API services that suits you
                </Typography.Title>
                <Typography.Text className="h1__description" level={6}>
                  Unlock your business potential with our seamless API integration
                </Typography.Text>
              </Col>

              <Col xs={24} sm={24} md={4} className="select__all">
                <Form.Item name="selectAll" noStyle valuePropName="checked">
                  <Checkbox
                    onChange={selectAllApiHandler}
                    checked={false}
                    className="tag-btn select__all_chk_box"
                    id="select-all-btn"
                  >
                    Select All
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.List name="selectApi">
            {fields => {
              return (
                <Fragment>
                  {fields.map(field => {
                    const apiName = selectApiForm.getFieldValue(['selectApi', field.name, 'apiName']);
                    const description = selectApiForm.getFieldValue(['selectApi', field.name, 'description']);
                    const apiId = selectApiForm.getFieldValue(['selectApi', field.name, 'apiId']);
                    const apiStatus = selectApiForm.getFieldValue(['selectApi', field.name, 'apiStatus']);
                    const notificationSupported = selectApiForm.getFieldValue([
                      'selectApi',
                      field.name,
                      'notificationSupported',
                    ]);
                    const tooltipText = selectApiForm.getFieldValue(['selectApi', field.name, 'tooltipText']);
                    return (
                      <div key={field.key} style={{ marginTop: 24 }}>
                        {apiStatus !== API_DEVELOPMENT_STATUS && (
                          <ApiComponent
                            apiTitle={apiName}
                            apiDescription={description}
                            apiId={apiId}
                            isSelectedFormFieldPath={[field.name, 'isSelected']}
                            apiStatus={apiStatus}
                            changeSelectAllFlag={changeSelectAllFlag}
                            notificationSupported={notificationSupported}
                            tooltipText={tooltipText}
                          />
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              );
            }}
          </Form.List>
        </Fragment>
        {clientConfig?.termsAndConditionToggle && (
          <LicenseAgreement isAgreementSelected={selectApiForm.getFieldInstance('isAgreementSelected')} />
        )}
      </Form>

      <div style={{ marginTop: 40 }}>
        <Row gutter={[24, 24]} align="middle" justify="center">
          <Col className="nav-button">
            <SecondaryButton type="default" className="btn-large-secondary" onClick={backToLegalEntity} id="reset-btn">
              Back
            </SecondaryButton>
          </Col>
          <Col className="nav-button">
            <SecondaryButton type="default" className="btn-large-secondary" onClick={openWarningModal} id="reset-btn">
              Cancel
            </SecondaryButton>
          </Col>
          <Col className="nav-button">
            <Button
              type="app-primary"
              className="login-form-button"
              htmlType="submit"
              onClick={() => selectApiForm?.submit()}
              id="next-btn"
            >
              Review and Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default ApiSelection;

ApiSelection.propTypes = {
  selectApiForm: object,
  partnerType: string,
  apiList: array,
  backToLegalEntity: func,
  nextToOverview: func,
  openWarningModal: func,
};
