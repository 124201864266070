import React from 'react';
import { Result } from 'antd';
import { string, node, bool } from 'prop-types';
import { cssResultContainer } from './ErrorComponent.style';

const getDefaultTitle = sectionName => `We're unable to load ${sectionName || 'data'}. ` + `You can try again later.`;

export default function Exception(props) {
  const { status, sectionName, title, subTitle, extra } = props;
  const style = props?.useWhiteBG ? cssResultContainer : null;

  return (
    <Result
      css={style}
      status={status || 'error'}
      title={title || getDefaultTitle(sectionName)}
      subTitle={subTitle || ''}
      extra={extra}
    />
  );
}

Exception.propTypes = {
  useWhiteBG: bool,
  sectionName: string,
  status: string,
  title: string,
  subTitle: string,
  extra: node,
};
