/* eslint-disable react/prop-types */
import React from 'react';
import { Button as AntDButton } from 'antd';
import { appPrimaryButton } from './Button.style';
// eslint-disable-next-line react/prop-types
const Button = props => {
  const style = props?.type === 'app-primary' ? appPrimaryButton : null;
  const type = props?.type !== 'app-primary' ? props?.type : 'default';
  return <AntDButton css={style} {...props} type={type} />;
};

export default Button;
